import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

// Import Components
import { DatePicker, Select, Button, Row, Col, Input, Space } from "antd";

const FilterWithDateOrg = ({
  onSubmit,
  onDownload,
  showDate,
  showOrg,
  showSearch,
  showCity,
  showVerifier,
  showStatus,
  showSortBy,
  handleSearch,
  isSubmitButtonLoading,
  isDownloadButtonLoading,
  isSearchButtonLoading,
  removeAllOrg,
  removeAllVerifier,
  multipleSelect,
}) => {
  const { orgList, verifiers } = useSelector((state) => state.common);

  const [startDate, setStartDate] = React.useState(
    moment(new Date(), "yyyy-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date(), "yyyy-MM-DD")
  );
  const [selectedGroup, setSelectedGroup] = React.useState(
    removeAllOrg || multipleSelect ? "" : { name: "All Org", uuid: -1 }
  );
  // const [ selectedGroup, setSelectedGroup ] = React.useState(orgList[0])
  const [selectedCity, setSelectedCity] = React.useState({
    id: -1,
    name: "All City",
    value: -1,
  });

  const [selectedVerifier, setSelectedVerifier] = React.useState(
    removeAllVerifier || multipleSelect ? "" : { name: "All Verifier", id: -1 }
  )

  const [selectedStatus, setSelectedStatus] = React.useState(multipleSelect ? [] : {
    id: -1,
    name: "All Status",
    value: -1,
  });

  const [selectedSortBy, setSelectedSortBy] = React.useState({
    id: 1,
    name: "Sort By Start Date",
    value: 'created_at',
  });

  const cities = [
    { id: -1, name: "All City", value: -1 },
    { id: 1, name: "Dhaka", value: "Dhaka" },
    { id: 2, name: "Chattogram", value: "Chattogram" },
    { id: 3, name: "In Station", value: "In" },
    { id: 4, name: "Out Station", value: "Out" },
  ];

  const allStatus = [
    { id: -1, name: "All Status", value: -1 },
    { id: 1, name: "Created", value: 1 },
    { id: 2, name: "On Going", value: 2 },
    { id: 3, name: "Hold", value: 3 },
    { id: 4, name: "Finished", value: 4 },
    { id: 5, name: "Cancelled", value: 5 },
    { id: 6, name: "Submitted", value: 6 },
    { id: 7, name: "Partially Submitted", value: 7 },
  ];

  const allSortBy = [
    { id: 1, name: "Sort By Start Date", value: "created_at" },
    { id: 2, name: "Sort By End Date", value: "end_date" },
  ];

  // On start date change
  const _onStartDateChange = (date) => {
    if (date) {
      const startDate = date;
      setStartDate(startDate);
    }
  };

  // On end date change
  const _onEndDateChange = (date) => {
    if (date) {
      const endDate = date;
      setEndDate(endDate);
    }
  };

  // Handle Selected Org
  const _handleSelectOrg = (value) => {
    if ( multipleSelect ) {
      setSelectedGroup(value)
      return
    }
    const selectedGroup = orgList.find((org) => org.uuid === value);

    setSelectedGroup(selectedGroup);
  };

  // Handle Selected City
  const _handleSelectedCity = (value) => {
    const selectedCity = cities.find((city) => city.value === value);
    setSelectedCity(selectedCity);
  };

  // Handle Selected Verifier
  const _handleSelectedVerifier = (value) => {
    if ( multipleSelect ) {
      setSelectedVerifier(value)
      return
    }
    const selectedVerifier = verifiers.find((verifier) => verifier.id === value)

    setSelectedVerifier(selectedVerifier)
  }

  // Handle Selected Status
  const _handleSelectedStatus = (value) => {
    if ( multipleSelect ) {
      setSelectedStatus(value)
      return
    }
    const selectedStatus = allStatus.find((status) => status.value === value);
    setSelectedStatus(selectedStatus);
  };

  // Handle Selected SortBy
  const _handleSelectedSortBy = (value) => {
    const selectedSortBy = allSortBy.find((sortBy) => sortBy.value === value);
    setSelectedSortBy(selectedSortBy);
  };

  return (
    <Row style={{ width: "100%" }}>
      <Col
        span={showSearch ? 18 : 24}
        md={12}
        lg={18}
        style={{ margin: "10px 0" }}
      >
        {showDate && (
          <>
            <DatePicker
              onChange={_onStartDateChange}
              format={"DD-MMM-yyyy"}
              value={startDate}
              allowClear={false}
            />

            <DatePicker
              onChange={_onEndDateChange}
              format={"DD-MMM-yyyy"}
              value={endDate}
              allowClear={false}
            />
          </>
        )}
        {showOrg && (
          <Select
            mode={multipleSelect ? "multiple" : ""}
            maxTagCount= 'responsive'
            style={{ width: "100%", maxWidth: 166 }}
            showSearch
            placeholder={"Select Org"}
            onChange={_handleSelectOrg}
            value={selectedGroup?.uuid}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp={"children"}
          >
            {
              // without AllOrg option
              removeAllOrg || multipleSelect
                ? orgList?.length > 0 &&
                orgList?.map((item, index) => {
                  return (
                    // all org removed index[0] == all org
                    index !== 0 ? (
                      <Select.Option key={index + 1} value={item?.uuid}>
                        {item?.name}
                      </Select.Option>
                    ) : (
                      ""
                    )
                  );
                })
                : // with AllOrg option
                orgList?.length > 0 &&
                orgList.map((item, index) => {
                  return (
                    <Select.Option key={index + 1} value={item.uuid}>
                      {item.name}
                    </Select.Option>
                  );
                })
            }
          </Select>
        )}
        {showCity && (
          <Select
            style={{ width: "100%", maxWidth: 166 }}
            showSearch
            placeholder={"Select City"}
            onChange={_handleSelectedCity}
            value={selectedCity.value}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp={"children"}
          >
            {cities.map((item, index) => {
              return (
                <Select.Option key={index + 1} value={item.value}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        )}
        {showVerifier && (
          <Select
            mode={multipleSelect ? "multiple" : ""}
            maxTagCount= 'responsive'
            style={{ width: "100%", maxWidth: 166 }}
            showSearch
            placeholder={"Select Verifier"}
            onChange={_handleSelectedVerifier}
            value={selectedVerifier?.id}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp={"children"}
          >
            {
              multipleSelect ? (
                 verifiers?.length > 0 &&
                [...verifiers].slice(1) // Create a copy of the verifiers array and remove first index
                  .sort((a, b) => {
                    if (multipleSelect && selectedVerifier.includes(a.id)) return -1;
                    if (multipleSelect && selectedVerifier.includes(b.id)) return 1;
                    return 0;
                  })
                  .map((item, index) => {
                    return (
                        <Select.Option key={index + 1} value={item?.id}>
                          {item?.name}
                        </Select.Option>
                    );
                  })
              ) :
              // without AllVerifier option
              removeAllVerifier
                ? verifiers?.length > 0 &&
                verifiers?.map((item, index) => {
                  return (
                    // all verifier removed index[0] == all Verifer
                    index !== 0 ? (
                      <Select.Option key={index + 1} value={item?.id}>
                        {item?.name}
                      </Select.Option>
                    ) : (
                      ""
                    )
                  );
                })
                : // with AllOrg option
                verifiers?.length > 0 &&
                verifiers.map((item, index) => {
                  return (
                    <Select.Option key={index + 1} value={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })
            }
          </Select>
        )}
        {showStatus && (
          <Select
            mode={multipleSelect ? "multiple" : ""}
            maxTagCount= 'responsive'
            style={{ width: "100%", maxWidth: 166, }}
            showSearch
            placeholder={"Select Status"}
            onChange={_handleSelectedStatus}
            value={selectedStatus.value}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp={"children"}
          >
            {allStatus.map((item, index) => {
              if (multipleSelect && index === 0) return null;
              return (
                <Select.Option key={index + 1} value={item.value}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        )}

        {showSortBy && (
          <Select
            style={{ width: "100%", maxWidth: 166, }}
            showSearch
            placeholder={"Select Sort By"}
            onChange={_handleSelectedSortBy}
            value={selectedSortBy.value}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            optionFilterProp={"children"}
          >
            {allSortBy.map((item, index) => {
              return (
                <Select.Option key={index + 1} value={item.value}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        )}

        <Space >
          {/* Submit */}
          <Button
            type={"primary"}
            loading={isSubmitButtonLoading}
            onClick={() =>
              onSubmit(
                startDate,
                endDate,
                selectedGroup,
                selectedCity,
                selectedStatus,
                selectedVerifier,
                selectedSortBy
              )
            }
          >
            {"Submit"}
          </Button>

          {/* Download */}
          {onDownload && (
            <Button
              type={"primary"}
              loading={isDownloadButtonLoading}
              onClick={() =>
                onDownload(
                  startDate,
                  endDate,
                  selectedGroup
                )}
            >
              {"Download"}
            </Button>
          )}
        </Space>
      </Col>
      {showSearch && (
        <Col span={24} md={12} lg={6} style={{ margin: "10px 0" }}>
          <Input.Search
            placeholder={"Search files.."}
            enterButton={"Search"}
            onSearch={handleSearch}
            loading={isSearchButtonLoading}
          />
        </Col>
      )}
    </Row>
  );
};

export default FilterWithDateOrg;
