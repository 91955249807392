import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import Components
import {
  Button,
  Space,
  Tag,
  Tooltip,
  Divider,
  Modal,
  Row,
  Col,
  Switch,
  Image,
} from "antd";
import { UpdateFileNo } from "../Application";
import MetaDataDetails from "./MetaDataDetails";
// import OcrAttachmentModal from "./OcrAttachmentModal";

// Import Icons
import { EditOutlined } from "@ant-design/icons";

// Import Actions, and Reducers
import { getApplicationStatus, showAlert } from "../../../Services/Actions/commonActions";
import {
  updateDownloadStatus,
  updateStation,
  updateVerifiedStatus,
} from "../../../Services/Actions/applicationActions";
import { setIsUpdateFileNoModalOpen } from "../../../Services/Reducers/applicationReducer";
import { isAllowedToAccess } from "../../../Services/Actions/permissionActions";
// import OcrAttachmentModal from "./OcrAttachmentModal";

class ApplicationHeader extends React.PureComponent {
  state = {
    isMetaDataModalOpen: false,
    isOcrAttachmentModalOpen: false,
  };

  onCancel = () => {
    this.setState({
      isOcrAttachmentModalOpen: false,
    });
  };

  _toggleMetaData = (option) => {
    this.setState({
      isMetaDataModalOpen: option,
    });
  };

  _toggleOcrAttachmentModal = (option) => {
    this.setState({
      isOcrAttachmentModalOpen: option,
    });
  };

  render() {
    const {
      applicationDetails,
      isUpdateFileNoModalOpen,
      dispatch,
      isPostActionAllowed,
      email,
      allowedPermission,
    } = this.props;
    const { isMetaDataModalOpen, isOcrAttachmentModalOpen } = this.state;
    console.log(applicationDetails, 'applicationDetails')


    // Toggle Verified Status
    const _toggleVerifiedStatus = (value) => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("file_no", applicationDetails?.application_id);
      formData.append("is_verified", value === 0 ? 1 : 0);

      dispatch(updateVerifiedStatus(applicationDetails, formData));
    };

    // Toggle Ready To Download
    const _toggleReadyToDownload = (value) => {
      const formData = new FormData();
      if (applicationDetails?.status === 6) {
        formData.append("_method", "PUT");
        formData.append("file_no", applicationDetails?.application_id);
        formData.append("is_download_enabled", value === 0 ? 1 : 0);

        dispatch(updateDownloadStatus(applicationDetails, formData));
      }
      else if (applicationDetails?.status === 4 || applicationDetails?.status === 5) {
        showAlert('error', `${applicationDetails?.status === 4 ? 'Finished' : 'Cancelled'} application status cannot be changed`)
      } else {
        showAlert('error', 'Application not Submitted yet')
      }
    };

    const _handleStationUpdate = (station) => {
      // Create payload
      const formData = new FormData()
      formData.append('_method', 'PUT')
      formData.append('station', station === "IN_STATION" ? "OUT_STATION" : "IN_STATION")

      // Update station
      dispatch(updateStation(applicationDetails, formData)).then((e) => {
        // console.log('station', e);
        if (e === 'success') {

        }
      })
    };

    return (
      <div>
        <Row>
          <Col xs={24}>
            <Space
              split={<Divider type="vertical" />}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <Space>
                <span>File No.: {applicationDetails?.application_id}</span>
                {
                  // Controlling Access
                  isPostActionAllowed === true && (
                    <Tooltip title={"Update file no."} placement={"topRight"}>
                      <Button
                        type={"primary"}
                        icon={<EditOutlined />}
                        onClick={() =>
                          dispatch(setIsUpdateFileNoModalOpen(true))
                        }
                      />
                    </Tooltip>
                  )
                }
              </Space>

              {/* File Status */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <span>File Status: </span>
                <Tag
                  color={
                    applicationDetails?.status
                      ? getApplicationStatus(applicationDetails?.status)?.color
                      : ""
                  }
                >
                  {applicationDetails?.status
                    ? getApplicationStatus(applicationDetails?.status)?.label
                    : "N/A"}
                </Tag>
              </div>

              {/* Update Verified Status */}
              {
                // PERMISSION ID OF "reports.reports.file.mark_as_verified" is 76
                isAllowedToAccess(allowedPermission, {
                  menuName: "reports",
                  subMenuName: "reports",
                  permissionID: "76",
                }) &&
                !email.includes("southeastbank") && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span>Verified Remarks:</span>
                    <Switch
                      checkedChildren="Satisfied"
                      unCheckedChildren="Not Satisfied"
                      checked={applicationDetails?.is_verified}
                      onChange={() =>
                        _toggleVerifiedStatus(applicationDetails?.is_verified)
                      }
                    />
                  </div>
                )
              }

              {/* Update Download Status */}
              {
                // PERMISSION ID OF "reports.reports.file.mark_ready_to_download" is 75
                isAllowedToAccess(allowedPermission, {
                  menuName: "reports",
                  subMenuName: "reports",
                  permissionID: "75",
                }) &&
                !email.includes("southeastbank") && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span>Ready To Download:</span>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="False"
                      checked={applicationDetails?.is_download_enabled}
                      onChange={() =>
                        _toggleReadyToDownload(
                          applicationDetails?.is_download_enabled
                        )
                      }
                    />
                  </div>
                )
              }
              {/* Update Station */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <style>
                  {`
                    .custom-switch.ant-switch-checked {
                      background-color: #1890ff !important; 
                    }
                    .custom-switch:not(.ant-switch-checked) {
                      background-color: red!important; 
                    }
                  `}
                </style>
                <span>Station:</span>
                <Switch
                  className="custom-switch"
                  checkedChildren="IN"
                  unCheckedChildren="OUT"
                  checked={applicationDetails?.station === "IN_STATION" ? true : false}
                  style={{ backgroundImage: "none" }}
                  onChange={() =>
                    _handleStationUpdate(
                      applicationDetails?.station
                    )
                  }
                />
              </div>

              {/* Open Meta */}
              <div style={{ float: "right" }}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => this._toggleMetaData(true)}
                  >
                    Open Meta
                  </Button>
                </Space>
              </div>
              {/* OCR Attachment */}
              {applicationDetails?.ocrs && (
                <div>
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => this._toggleOcrAttachmentModal(true)}
                    >
                      OCR Attachment
                    </Button>
                  </Space>
                </div>
              )}
            </Space>
          </Col>
          {isMetaDataModalOpen && (
            <Modal
              visible={isMetaDataModalOpen}
              title={"Meta Details"}
              onCancel={() => this._toggleMetaData(false)}
              maskClosable={false}
              footer={null}
              width={"100%"}
              style={{
                // top: 0,
                minWidth: "320px",
                maxWidth: "600px",
              }}
            >
              <MetaDataDetails applicationDetails={applicationDetails} />
            </Modal>
          )}
          {isUpdateFileNoModalOpen && <UpdateFileNo />}
          
        </Row>
        {isOcrAttachmentModalOpen && (
          <Modal
          visible={isOcrAttachmentModalOpen}
          title="OCR Attachments"
          onCancel={this.onCancel}
          footer={null}
          width="80%"
        >
          <Row gutter={[16, 16]} style={{ overflowY: 'auto', maxHeight: '70vh' }}>
            {applicationDetails.ocrs.map((ocr, index) =>
              ocr.attachments.map((attachment, idx) => (
                <Col key={`${index}-${idx}`} span={8}>
                  <div style={{ textAlign: 'center' }}>
                    <p>{attachment.attachment_type}</p> 
                    <Image
                      src={attachment.url}
                      alt={attachment.attachment_type}
                      title={attachment.attachment_type}
                      style={{ width: '60%', height: 'auto' }} 
                    />
                  </div>
                </Col>
              ))
            )}
          </Row>
        </Modal>
        )}
      </div>
    );
  }
}

// Props Validation
ApplicationHeader.propTypes = {
  dispatch: PropTypes.func,
  applicationDetails: PropTypes.object,
  isFileUploadModalOpen: PropTypes.bool,
  isUpdateFileNoModalOpen: PropTypes.bool,
  isPostActionAllowed: PropTypes.bool,
};

ApplicationHeader.defaultProps = {
  dispatch: () => null,
  applicationDetails: {},
  isFileUploadModalOpen: false,
  isUpdateFileNoModalOpen: false,
  isPostActionAllowed: false,
  allowedPermission: null,
};

// Map State To Props
const mapStateToProps = (state) => ({
  email: state.auth?.user?.email,
  applicationDetails: state.application.applicationDetails,
  isFileUploadModalOpen: state.application.isFileUploadModalOpen,
  isUpdateFileNoModalOpen: state.application.isUpdateFileNoModalOpen,
  allowedPermission: state?.permission?.allowedPermission ?? null,
});

// Map Dispatch To Props
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationHeader);
