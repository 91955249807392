import { Badge, Space, Table, Row, Col, Card, Typography, Alert } from 'antd';
import React from 'react';
import { ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

const ExpandedTable = ({ data, isLoading, error, onRetry }) => {
  // Make sure we have a valid array for data even when it's loading or null
  const tableData = Array.isArray(data) ? data : [];
  
  // If there's an error, display a compact error message
  if (error) {
    const isDateRangeError = error.includes("Date range");
    
    return (
      <Alert
        message={isDateRangeError ? "Date Range Too Large" : "Error Loading Data"}
        description={
          <div>
            <div>{error}</div>
            {isDateRangeError && (
              <Text type="secondary" style={{ fontSize: '12px' }}>
                Please select a date range of 45 days or less
              </Text>
            )}
          </div>
        }
        type="warning"
        showIcon
        icon={isDateRangeError ? <ClockCircleOutlined /> : <InfoCircleOutlined />}
        style={{ margin: '10px' }}
      />
    );
  }

  const expandedRowRender = (record) => {
    // Files Summary Table
    const fileColumns = [
      {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
      },
      {
        title: 'On Going',
        dataIndex: 'on_going',
        key: 'on_going',
      },
      {
        title: 'Hold',
        dataIndex: 'hold',
        key: 'hold',
      },
      {
        title: 'Finished',
        dataIndex: 'finished',
        key: 'finished',
      },
      {
        title: 'Submitted',
        dataIndex: 'submitted',
        key: 'submitted',
      },
      {
        title: 'Partially Submitted',
        dataIndex: 'partially_submitted',
        key: 'partially_submitted',
      },
      {
        title: 'Cancelled',
        dataIndex: 'cancelled',
        key: 'cancelled',
      },
      {
        title: 'Finished %',
        dataIndex: 'file_finished_percentage',
        key: 'file_finished_percentage',
        render: (value) => `${value}%`,
      },
    ];

    // Trips Summary Table
    const tripColumns = [
      {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
      },
      {
        title: 'Allocated',
        dataIndex: 'allocated',
        key: 'allocated',
      },
      {
        title: 'Started',
        dataIndex: 'started',
        key: 'started',
      },
      {
        title: 'On Going',
        dataIndex: 'on_going',
        key: 'on_going',
      },
      {
        title: 'Hold',
        dataIndex: 'hold',
        key: 'hold',
      },
      {
        title: 'Finished',
        dataIndex: 'finished',
        key: 'finished',
      },
      {
        title: 'Cancelled',
        dataIndex: 'cancelled',
        key: 'cancelled',
      },
      {
        title: 'Inactive',
        dataIndex: 'inactive',
        key: 'inactive',
      },
      {
        title: 'Re CPV',
        dataIndex: 're_cpv',
        key: 're_cpv',
      },
      {
        title: 'Finished %',
        dataIndex: 'trips_finished_percentage',
        key: 'trips_finished_percentage',
        render: (value) => `${value}%`,
      },
    ];

    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card title="Files Summary" bordered={false}>
          <Table 
            columns={fileColumns} 
            dataSource={[record.files]} 
            pagination={false} 
            rowKey={() => 'file-summary'} 
            loading={isLoading}
          />
        </Card>
        <Card title="Trips Summary" bordered={false}>
          <Table 
            columns={tripColumns} 
            dataSource={[record.trips]} 
            pagination={false} 
            rowKey={() => 'trip-summary'} 
            loading={isLoading}
          />
        </Card>
      </Space>
    );
  };

  const columns = [
    {
      title: 'Organization Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <b>{text}</b>,
    },
    {
      title: 'Total Files',
      dataIndex: 'total_files',
      key: 'total_files',
    },
    {
      title: 'Total Trips',
      dataIndex: 'total_trips',
      key: 'total_trips',
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        loading={isLoading}  
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: false,
        }}
        dataSource={tableData.map((item, index) => ({ ...item, key: index.toString() }))}
        size="middle"
      />
    </>
  );
};

export default ExpandedTable;