import { Button, Table } from "antd";
import React, { useEffect, useState } from "react";
import OCRDocumentCreateFile from "./OCRDocumentCreateFile";
import { render } from "@testing-library/react";
import { Link } from "react-router-dom";
import {
  getOCRList,
  getOrgOptions,
  getOrgTypes,
  transformORCList,
} from "../../../../../Services/Actions/createFileActions";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationType } from "../../../../../Services/Actions/staticOptionAction";
import {
  setOCRProcessing,
  setOcrResults,
  setPreviewImages,
  setSelectedOption,
} from "../../../../../Services/Reducers/createFileReducer";
import axios from "axios";
import { CREATE_FILE } from "../../../../../Config/config";
import { showAlert } from "../../../../../Services/Actions/commonActions";
import { getOrganizationStation } from "../../../../../Services/Actions/adminPanelActions";
import dayjs from "dayjs";

const OCRCreateFile = () => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ocrDocumentsList, setOcrDocumentsList] = useState([]);
  const isOCRLoading = useSelector((state) => state.createFile.isOCRLoading);
  const dispatch = useDispatch();
  const ocrList = useSelector((state) => state.createFile.OCRList);
  const orgList = useSelector((state) => state.common.orgList);
  const [stationOptions, setStationOptions] = useState([]);
  
  // images state
  const [applicantImages, setApplicantImages] = useState([]);
  const [loiImages, setLoiImages] = useState([]);
  const [allImages, setAllImages] = useState([]);
//images object
const imagesObj={
  applicant_images: applicantImages,
  allImages: allImages,
  loiImages: loiImages
};

  useEffect(() => {
    const transformedORCList = transformORCList(ocrList, orgList);
    // console.log(transformedORCList, 'transformedORCList');
    setOcrDocumentsList(transformedORCList);
  }, [ocrList, orgList]);
  // Get OCR List
  useEffect(() => {
    dispatch(getOCRList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // preview images
  let images = [];
  let applicant_images = [];
  let all_images = [];
  let loi = [];
  //organization name and type
  let orgName = "";
  let type = ``;

  // OCR Document
  const OCRDocument = async (record) => {
    setSelectedRow(record);

    // Get Organization Options and Station Options to show in form disabled fields
    dispatch(getOrgOptions({ org_id: record?.organization_id }));
    dispatch(
      getOrganizationStation({
        org_id: record?.organization_id,
        application_type: record?.application_type,
      })
    ).then((result) => {
      setStationOptions(result);
    });
    // Set OCR Processing `true`
    dispatch(setOCRProcessing(true));
    // Clear Previous Images
    images = [];
    dispatch(setPreviewImages([]));

    // Declare formData
    let formData = new FormData();

    // Download images from URLs and append to formData
    for (const attachment of record?.attachments || []) {
      if (attachment.attachment_type === "OCR_DOC") {
        const imageResponse = await fetch(attachment.url);
        const imageBlob = await imageResponse.blob();
        const imageFile = new File([imageBlob], "image.jpg", {
          type: "image/jpeg",
        });
        formData.append("file", imageFile);
        images.push({ url: URL.createObjectURL(imageFile) });
        // console.log(images, "images");
      }
      if (attachment.attachment_type === "APPLICANT") {
          const imageResponse = await fetch(attachment.url);
          const imageBlob = await imageResponse.blob();
          const imageFile = new File([imageBlob], "image.jpg", {
            type: "image/jpeg",
          });
        applicant_images.push(imageFile);
      }
      if (attachment.attachment_type === "1") {
        loi.push({ url: attachment.url });
      }
      if (attachment.attachment_type !== "1" && attachment.attachment_type !== "OCR_DOC") {
        all_images.push(attachment.url);
      }
    }

    // Set Applicant Images
    setApplicantImages(applicant_images);
    setLoiImages(loi);
    setAllImages(all_images);
    // Set Preview Images
    dispatch(setPreviewImages(images));

    // Set Organization Name
    if (record && record.name === "LankaBangla Finance Limited") {
      orgName = "lanka_bangla";
    } else if (record && record.name === "Jamuna Bank") {
      orgName = "jamuna";
    } else if (record && record.name === "IDLC") {
      orgName = "idlc";
    } else if (record && record.name === "City Bank Limited") {
      orgName = "city";
    } else {
      orgName = "";
      dispatch(setOCRProcessing(false));
      dispatch(setOcrResults(null));
      return showAlert("error", "OCR is not possible for this organization");
    }

    // Set Application Type
    if (record && record.application_type === 1) {
      type = "loan";
    } else if (record && record.application_type === 2) {
      type = "card";
    } else {
      type = "";
    }

    // Append `bank_name` & `type`
    formData.append("bank_name", orgName);
    formData.append("type", type);

    // console.log(images, 'images');

    // Call API
    axios
      .post(CREATE_FILE.OCR, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // Show Alert
        showAlert("success", "OCR Result Found");

        // Set Results
        dispatch(setOcrResults(res.data.results));

        // Set Preview Images
        dispatch(setPreviewImages(images));
      })
      .catch(() => {
        // Show Alert
        showAlert("error", "OCR Result Not Found");

        // Clear Previous States
        images = [];
        dispatch(setOcrResults(null));
        // dispatch(setPreviewImages([]))
      })
      .finally(() => {
        // Set OCR Processing `false`
        dispatch(setOCRProcessing(false));
      });
  };

  const columns = [
    {
      title: "Sl No.",
      dataIndex: "id",
      key: "id",
      position: "left",
    },
    {
      title: "File number",
      dataIndex: "application_number",
      key: "application_number",
      render: (text, record) => (
        <Button type="link" onClick={() => OCRDocument(record)}>
          {text}
        </Button>
      ),
    },
    {
      title: "Organization",
      dataIndex: "name",
      key: "name",
      filters: Array.from(new Set(ocrDocumentsList.map((d) => d.name))).map(
        (d) => ({ text: d, value: d })
      ),
      filterMode: "none",
      filterSearch: true,
      onFilter: (value, record) => record.name.startsWith(value),
    },
    {
      title: "File Type",
      dataIndex: "application_type",
      key: "application_type",
      render: (text, record) =>
        getApplicationType().find(
          (d) => d.app_type_id === record.application_type
        )?.app_type,
    },
    {
      title: "File Sub Type",
      dataIndex: "subTypes",
      key: "subTypes",
    },
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
    },
    {
      title: "Provided At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => dayjs(text).format("DD-MM-YYYY hh:mm A"),
    },
    {
      title: "File Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={ocrDocumentsList.slice().reverse()}
        rowKey={(record) => record.id}
        loading={isOCRLoading}
        bordered
        style={{ width: "100%", overflow: "auto" }}
        size="small"
        pagination={{
          pageSize: 3,
          showSizeChanger: false,
        }}
      />

      {/* <OCRDocuments /> */}
      {selectedRow && (
        <OCRDocumentCreateFile
          selectedOrgData={selectedRow}
          stationOptions={stationOptions}
          setSelectedRow={setSelectedRow}
          imagesObj={imagesObj}
        />
      )}
    </div>
  );
};

export default OCRCreateFile;
