import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import store from '../../../Services/Store/store'
import store from '../../../../../Services/Store/store'

// Import Components
import { Button, Form, Space } from 'antd'
// import ConcernFormWrapper from './ConcernFormWrapper'
// import LoiUpload from './LoiUpload'

// Import Actions, and Reducers
import { validateConcern, createNewFile, createConcernsAndTrips, updateOCRFileStatus } from '../../../../../Services/Actions/createFileActions'
// import { showAlert } from '../../../Services/Actions/commonActions'
import { setIsResetClicked, setSelectedOption, setMarkerData, setOcrResults, setPreviewImages, setOCRProcessing, setFormData, setCoApplicantNo } from '../../../../../Services/Reducers/createFileReducer'
import Applications from './Applications'
import ConcernFormWrapperOCR from './ConcernFormWrapperOCR'
import LoiUpload from '../../LoiUpload'
import { showAlert } from '../../../../../Services/Actions/commonActions'

class CreateFileFormOCR extends React.PureComponent {

    // Create Form Ref
    _formRef = React.createRef()
    _fileRef = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            file: null
        }
    }

    async componentDidMount() {
        const files = await Promise.all(
          this.props.imagesObj.loiImages.map(async (imageObj, index) => {
            const response = await fetch(imageObj.url);
            const blob = await response.blob();
            const baseUrl = imageObj.url.split('?')[0];
            const file = new File([blob], baseUrl.split('/').pop(), { type: 'image/jpeg' });
      
            return {
              uid: `rc-upload-${Date.now()}-${index}`,
              name: file.name,
              status: 'done',
              url: imageObj.url,
              size: file.size,
              type: file.type,
              originFileObj: file,
            };
          })
        );
      
        this.setState({ file: files });
      }
      
      setFile = (file) => {
        this.setState({ file })
      }
    // Clear Form
    _clearForm = () => {
        const { dispatch } = this.props

        // Set reset clicked to true
        dispatch(setIsResetClicked(true))

        // Set Reset Clicked to false
        setTimeout(() => {
            dispatch(setIsResetClicked(false))
        }, 200)

        // Set Selected Option to null
        dispatch(setSelectedOption(null))

        // Set Co Applicant No
        dispatch(setCoApplicantNo(0))

        // Clear Marker Data
        dispatch(setMarkerData([]))

        // Clear OCR Results
        dispatch(setOcrResults(null))

        // Clear OCR Preview Images
        dispatch(setPreviewImages([]))

        // Set OCR Professing `false`
        dispatch(setOCRProcessing(false))

        // Clear _formRef
        this._formRef.current.resetFields()

        // Clear _fileRef
        // this._fileRef.current.value = null
        this.setState({ file: null })

        // Clear Form Data from Redux
        dispatch(setFormData({}))
    }

    // Handle Submit
    _handleSubmit = values => {
        const { dispatch, formData } = this.props

        let files = this._fileRef.current?.files ?? []
        // console.log(this.state.file, 'files');

        if (this.state.file && this.state.file.length > 0) {
            // console.log('inside');
            const fileList = this.state.file.map((value) => value.originFileObj ? value.originFileObj : value)
            // console.log(fileList, 'fileList---');
            // const fileObj = {file: fileList.map(([key, value]) => ({[key]: value})), length: fileList.length }   

            files = fileList
            // console.log(files, 'fileObj');
        }
        // Validating if trip and concern information is provided or not
        const data = dispatch(validateConcern(formData))
        // console.log(data, 'data')
        // If data is not valid
        if (!data) {
            // Show Alert
            showAlert('error', 'Concern field is required')
        }

        if (data) {
            dispatch(createNewFile(data, files, this._clearForm, this.props.selectedOrgData.id))
            // .then((res) => { 
            //     // console.log(res, 'res')
            //     if(res?.status === 200){
            //         dispatch(updateOCRFileStatus(this.props.selectedOrgData)).then(() => {
            //             this.props.setSelectedRow(null) // Clear Selected Row
            //         })
            //     }
            // })
        }
    }

    componentDidUpdate = () => {
        const { selectedOption, ocrResults, dispatch } = this.props

        // Get Current Redux Form Data
        const formData = store.getState().createFile.formData

        // If OCR Result Found
        if (selectedOption?.id && ocrResults) {
            // Define Form Data
            let _formData = {}

            if (ocrResults?.co_applicant) {
                const _application = this._formRef.current.getFieldValue('application')
                // console.log(_application, 'application')
                // Set CoApplication Number to `formRef`
                this._formRef.current.setFieldsValue({
                    application: {
                        ..._application,
                        coapplicant_no: ocrResults?.co_applicant?.length ?? 0
                    }
                })

                // Set CoApplication Number to _formData
                _formData = {
                    ..._formData,
                    application: {
                        ..._formData.application,
                        coapplicant_no: ocrResults?.co_applicant?.length ?? 0
                    }
                }

                // Set Redux Form Data
                dispatch(setFormData({
                    ...formData,
                    application: {
                        ...formData.application,
                        coapplicant_no: ocrResults?.co_applicant?.length ?? 0
                    }
                }))

                // Set CoApplication Number to _formData
                dispatch(setCoApplicantNo(ocrResults?.co_applicant?.length ?? 0));
            }


            // ============ //
            // Set Concerns //
            // ============ //

            // Find Out Concerns
            const concerns = createConcernsAndTrips(selectedOption?.id, ocrResults?.co_applicant?.length ?? 0)
            // console.log(concerns, 'concerns')
            concerns.forEach((concern) => {
                // console.log(concern, 'concern')
                if (concern.identifier === 'APPLICANT' && concern.label !== 'Optional') {
                    // Set `APPLICANT` _formRef From OCR Result
                    this._formRef.current.setFieldsValue({
                        [concern.identifier]: {
                            name: ocrResults?.applicant?.basic_info?.name ?? '',
                            spouse: ocrResults?.applicant?.basic_info?.spouse_name ?? '',
                            phone: ocrResults?.applicant?.basic_info?.contact ?? '',
                            designation: ocrResults?.applicant?.office?.designation ?? '',
                            image: null,
                            isRequired: false
                        }
                    })

                    // Set `APPLICANT` Form Data From OCR Result
                    _formData = {
                        ..._formData,
                        [concern.identifier]: {
                            name: ocrResults?.applicant?.basic_info?.name ?? '',
                            spouse: ocrResults?.applicant?.basic_info?.spouse_name ?? '',
                            phone: ocrResults?.applicant?.basic_info?.contact ?? '',
                            designation: ocrResults?.applicant?.office?.designation ?? '',
                            image: null,
                            isRequired: false
                        }
                    }
                } else if (concern.type === 'CO_APPLICANT' && concern.label !== 'Optional') {
                    // Find Current Value
                    ocrResults.co_applicant.forEach((_coApplicant) => {
                        // Find index to set unique value based on loop
                        const _index = concern.label[concern.label.length - 1] - 1

                        // Find Current Concern
                        const currentConcern = this._formRef.current.getFieldValue(concern.identifier)

                        // If Current Concern Data Is Not Set Yet
                        if (!currentConcern) {
                            // Set `CO_APPLICANT` _formRef From OCR Result
                            this._formRef.current.setFieldsValue({
                                [concern.identifier]: {
                                    name: ocrResults.co_applicant[_index]?.basic_info?.name ?? '',
                                    spouse: ocrResults.co_applicant[_index]?.basic_info?.spouse_name ?? '',
                                    phone: ocrResults.co_applicant[_index]?.basic_info?.contact ?? '',
                                    designation: ocrResults.co_applicant[_index]?.office?.designation ?? '',
                                    image: null,
                                    isRequired: false
                                }
                            })

                            // Set `CO_APPLICANT` Form Data From OCR Result
                            _formData = {
                                ..._formData,
                                [concern.identifier]: {
                                    name: ocrResults.co_applicant[_index]?.basic_info?.name ?? '',
                                    spouse: ocrResults.co_applicant[_index]?.basic_info?.spouse_name ?? '',
                                    phone: ocrResults.co_applicant[_index]?.basic_info?.contact ?? '',
                                    designation: ocrResults.co_applicant[_index]?.office?.designation ?? '',
                                    image: null,
                                    isRequired: false
                                }
                            }
                        }

                    })
                } else if ((concern.identifier === 'FIRST_GUARANTOR' || concern.identifier === 'SECOND_GUARANTOR') && concern.label !== 'Optional') {

                    ocrResults?.guarantor?.forEach((_guarantor, i) => {
                        // Set FIRST_GUARANTOR and SECOND_GUARANTOR `_formRef` From OCR Result
                        this._formRef.current.setFieldsValue({
                            [i === 0 ? 'FIRST_GUARANTOR' : 'SECOND_GUARANTOR']: {
                                name: _guarantor?.basic_info?.name ?? '',
                                spouse: _guarantor?.basic_info?.spouse_name ?? '',
                                phone: _guarantor?.basic_info?.contact ?? '',
                                designation: _guarantor?.office?.designation ?? '',
                                image: null,
                                isRequired: false
                            }
                        })

                        // Set FIRST_GUARANTOR and SECOND_GUARANTOR `Form Data` From OCR Result
                        _formData = {
                            ..._formData,
                            [i === 0 ? 'FIRST_GUARANTOR' : 'SECOND_GUARANTOR']: {
                                name: _guarantor?.basic_info?.name ?? '',
                                spouse: _guarantor?.basic_info?.spouse_name ?? '',
                                phone: _guarantor?.basic_info?.contact ?? '',
                                designation: _guarantor?.office?.designation ?? '',
                                image: null,
                                isRequired: false
                            }
                        }


                        // Set GUARANTOR_HOUSE `_formRef` From OCR Result
                        this._formRef.current.setFieldsValue({
                            [i === 0 ? 'FIRST_GUARANTOR' : 'SECOND_GUARANTOR']: {
                                [`GUARANTOR_HOUSE`]: {
                                    address: _guarantor?.address?.present_address?.address ?? '',
                                    exact_address: _guarantor?.address?.present_address?.address ?? 'test'
                                }
                            }
                        })

                        // Set GUARANTOR_HOUSE `Form Data` From OCR Result
                        _formData = {
                            ..._formData,
                            [i === 0 ? 'FIRST_GUARANTOR' : 'SECOND_GUARANTOR']: {
                                ..._formData?.[i === 0 ? 'FIRST_GUARANTOR' : 'SECOND_GUARANTOR'],
                                [`GUARANTOR_HOUSE`]: {
                                    address: _guarantor?.address?.present_address?.address ?? '',
                                    exact_address: _guarantor?.address?.present_address?.address ?? 'test'
                                }
                            }
                        }

                        // Set GUARANTOR_OFFICE `_formRef` From OCR Result
                        this._formRef.current.setFieldsValue({
                            [i === 0 ? 'FIRST_GUARANTOR' : 'SECOND_GUARANTOR']: {
                                [`GUARANTOR_OFFICE`]: {
                                    address: _guarantor?.office?.address ?? '',
                                    exact_address: _guarantor?.office?.address ?? '',
                                    placename: _guarantor?.office?.name ?? '',
                                }
                            }
                        })

                        // Set GUARANTOR_OFFICE Form Data From OCR Result
                        _formData = {
                            ..._formData,
                            [i === 0 ? 'FIRST_GUARANTOR' : 'SECOND_GUARANTOR']: {
                                ..._formData?.[i === 0 ? 'FIRST_GUARANTOR' : 'SECOND_GUARANTOR'],
                                [`GUARANTOR_OFFICE`]: {
                                    address: _guarantor?.office?.address ?? '',
                                    exact_address: _guarantor?.office?.address ?? '',
                                    placename: _guarantor?.office?.name ?? '',
                                }
                            }
                        }

                    })
                }
                // ========= //
                // Set Trips //
                // ========= //
                concern.trips.forEach((trip) => {
                    if (trip.identifier === 'HOUSE') {
                        if (trip.concern.identifier === 'APPLICANT') {
                            // Set HOUSE Trip `_formRef` From OCR for APPLICANT
                            this._formRef.current.setFieldsValue({
                                [`${trip.concern.identifier}`]: {
                                    [`${trip.identifier}`]: {
                                        address: ocrResults?.applicant?.address?.present_address?.address ?? '',
                                        exact_address: ocrResults?.applicant?.address?.present_address?.address ?? '',
                                    }
                                }
                            })

                            // Set HOUSE Trip `Form Data` From OCR for APPLICANT
                            _formData = {
                                ..._formData,
                                [`${trip.concern.identifier}`]: {
                                    ..._formData?.[`${trip.concern.identifier}`],
                                    [`${trip.identifier}`]: {
                                        address: ocrResults?.applicant?.address?.present_address?.address ?? '',
                                        exact_address: ocrResults?.applicant?.address?.present_address?.address ?? '',
                                    }
                                }
                            }
                        } else if (trip.concern.identifier.includes('CO_APPLICANT')) {
                            ocrResults.co_applicant.forEach((_coApplicant, index) => {
                                // Set HOUSE Trip `_formRef` From OCR for CO_APPLICANT
                                this._formRef.current.setFieldsValue({
                                    [`${trip.concern.identifier}`]: {
                                        [`${trip.identifier}`]: {
                                            address: ocrResults?.co_applicant?.[index]?.address?.present_address?.address ?? '',
                                            exact_address: ocrResults?.co_applicant?.[index]?.address?.present_address?.address ?? '',
                                        }
                                    }
                                })

                                // Set HOUSE Trip `Form Data` From OCR for CO_APPLICANT
                                _formData = {
                                    ..._formData,
                                    [`${trip.concern.identifier}`]: {
                                        ..._formData?.[`${trip.concern.identifier}`],
                                        [`${trip.identifier}`]: {
                                            address: ocrResults?.co_applicant?.[index]?.address?.present_address?.address ?? '',
                                            exact_address: ocrResults?.co_applicant?.[index]?.address?.present_address?.address ?? '',
                                        }
                                    }
                                }
                            })
                        }

                    } if (trip.identifier === 'OFFICE') {
                        if (trip.concern.identifier === 'APPLICANT') {
                            // Set OFFICE Trip `_formRef` From OCR
                            this._formRef.current.setFieldsValue({
                                [`${trip.concern.identifier}`]: {
                                    [`${trip.identifier}`]: {
                                        address: ocrResults?.applicant?.office?.address ?? '',
                                        exact_address: ocrResults?.applicant?.office?.address ?? '',
                                        placename: ocrResults?.applicant?.office?.name ?? '',
                                    }
                                }
                            })

                            // Set OFFICE Trip `Form Data` From OCR
                            _formData = {
                                ..._formData,
                                [`${trip.concern.identifier}`]: {
                                    ..._formData?.[`${trip.concern.identifier}`],
                                    [`${trip.identifier}`]: {
                                        address: ocrResults?.applicant?.office?.address ?? '',
                                        exact_address: ocrResults?.applicant?.office?.address ?? '',
                                        placename: ocrResults?.applicant?.office?.name ?? '',
                                    }
                                }
                            }
                        } else if (trip.concern.identifier.includes('CO_APPLICANT')) {
                            ocrResults.co_applicant.forEach((_coApplicant, index) => {
                                // Find index to set unique value based on loop
                                const _index = concern.label[concern.label.length - 1] - 1

                                // Find Current Concern
                                const currentConcern = this._formRef.current.getFieldValue(concern.identifier)

                                // Find Current Trip
                                const currentTrip = currentConcern?.[trip.concern.identifier]

                                // If Current Trip Data is Not Set Yet
                                if (!currentTrip) {
                                    // Set OFFICE Trip `_formRef` From OCR for CO_APPLICANT
                                    this._formRef.current.setFieldsValue({
                                        [`${trip.concern.identifier}`]: {
                                            [`${trip.identifier}`]: {
                                                address: ocrResults?.co_applicant?.[_index]?.office?.address ?? '',
                                                exact_address: ocrResults?.co_applicant?.[_index]?.office?.address ?? '',
                                                placename: ocrResults?.co_applicant?.[_index]?.office?.name ?? '',
                                            }
                                        }
                                    })

                                    // Set OFFICE Trip `Form Data` From OCR for CO_APPLICANT
                                    _formData = {
                                        ..._formData,
                                        [`${trip.concern.identifier}`]: {
                                            ..._formData?.[`${trip.concern.identifier}`],
                                            [`${trip.identifier}`]: {
                                                address: ocrResults?.co_applicant?.[_index]?.office?.address ?? '',
                                                exact_address: ocrResults?.co_applicant?.[_index]?.office?.address ?? '',
                                                placename: ocrResults?.co_applicant?.[_index]?.office?.name ?? '',
                                            }
                                        }
                                    }
                                }
                            })
                        }
                    } else if (trip.identifier === 'RENTAL') {
                        if (trip.concern.identifier === 'APPLICANT') {
                            // Set RENTAL Trip `_formRef` From OCR
                            this._formRef.current.setFieldsValue({
                                [`${trip.concern.identifier}`]: {
                                    [`${trip.identifier}`]: {
                                        address: ocrResults?.applicant?.address?.rented_address?.address ?? '',
                                        exact_address: ocrResults?.applicant?.address?.rented_address?.address ?? '',
                                    }
                                }
                            })

                            // Set RENTAL Trip `Form Data` From OCR
                            _formData = {
                                ..._formData,
                                [`${trip.concern.identifier}`]: {
                                    ..._formData?.[`${trip.concern.identifier}`],
                                    [`${trip.identifier}`]: {
                                        address: ocrResults?.applicant?.address?.rented_address?.address ?? '',
                                        exact_address: ocrResults?.applicant?.address?.rented_address?.address ?? '',
                                    }
                                }
                            }
                        }
                    }
                })
            })

            // Set Form Data to Redux
            dispatch(setFormData(
                {
                    ..._formData,
                    application: formData.application,
                    group_uuid: this.props.selectedOrgData?.organization_id ?? formData.group_uuid,
                }))
            // Clear OCR Results
            dispatch(setOcrResults(null))
        }
    }

    render() {
        const { isLoading, user, imagesObj } = this.props
        return (
            <Form
                scrollToFirstError={{ block: 'center' }}
                layout={'vertical'}
                ref={this._formRef}
                onFinish={this._handleSubmit}
                labelCol={{ span: 6, xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 5 }}
                wrapperCol={{ span: 24, xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 19 }}
            >
                {/* Basic Application / File Type */}
                <Applications
                    clearForm={this._clearForm}
                    formRef={this._formRef}
                    defaultUserOrg={user}
                    selectedOrgData={this.props.selectedOrgData}
                    stations={this.props.stationOptions}
                />
                {/* Application / File Details */}
                <ConcernFormWrapperOCR formRef={this._formRef} imagesObj={imagesObj}/>

                {/* LOI Upload */}
                <div style={{ marginBottom: '20px' }}>
                    <span>Upload LOI Documents</span>
                    <div style={{ marginTop: '10px' }}>
                        <LoiUpload setFile={this.setFile} fileList={this.state.file} buttonVisible={true}/>
                    </div>
                </div>

                {/* Action */}
                <Form.Item wrapperCol={{ span: 24 }}>
                    <Space style={{ width: '100%', justifyContent: 'flex-start' }}>
                        <Button type={'default'} danger onClick={this._clearForm}>{'Reset'}</Button>
                        <Button type={'primary'} htmlType={'submit'} loading={isLoading}>{'Submit'}</Button>
                    </Space>
                </Form.Item>
            </Form>
        )
    }
}

// PropTypes
CreateFileFormOCR.propTypes = {
    isLoading: PropTypes.bool,
    user: PropTypes.object,
    selectedOption: PropTypes.object,
    coApplicantNo: PropTypes.number,
    ocrResults: PropTypes.object,
    formData: PropTypes.object
}

// Default Props
CreateFileFormOCR.defaultProps = {
    isLoading: false,
    user: {},
    selectedOption: {},
    coApplicantNo: 0,
    ocrResults: null,
    formData: {}
}

// Map State To Props
const mapStateToProps = state => ({
    isLoading: state.createFile.isLoading,
    user: state?.auth?.user ?? null,
    selectedOption: state.createFile.selectedOption,
    coApplicantNo: state.createFile.coApplicantNo,
    ocrResults: state?.createFile?.OCR?.results ?? null,
    formData: state?.createFile?.formData ?? {}
})

// Map Dispatch To Props
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(CreateFileFormOCR)