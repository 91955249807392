import axios from 'axios'

// Import Actions
import { setOrgOptions, setSelectedOption, setIsLoading, setIsResetClicked, setMarkerData, setOCRList, setIsOCRLoading } from '../Reducers/createFileReducer'
import { showAlert } from '../Actions/commonActions'
import store from "./../Store/store"

// Import Types
import { CREATE_FILE } from '../../Config/config'
import { setIsFileUploading } from '../Reducers/applicationReducer'

// Get Options for Organization
const getOrgOptions = params => {
    return async dispatch => {
        try {
            const response = await axios.get(CREATE_FILE.GET_ORG_OPTIONS, { params })
            const options = response?.data?.options ?? []
            
            // Transform options
            const transformedOptions = transformOrgTypes(options)            

            // Set Options
            dispatch(setOrgOptions(transformedOptions))

            // Set Selected Options to empty object
            dispatch(setSelectedOption({}))
        } catch (error) {
            console.error(error)

            // Show Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong')
        }
    }
}

const getOrgTypes = async (record) => {
    // const params = {org_id: record.organization_id};
        const modifiedRecord = record.map(async e => {
            const params = {org_id: e.organization_id};
            try {
                const response = await axios.get(CREATE_FILE.GET_ORG_OPTIONS, { params })
                const options = response?.data?.options ?? []
                const subTypes = options.find((d) => d.app_subtype_id === e.application_sub_type)?.app_subtype ?? ''
                return { ...e, subTypes }
            } catch (error) {
                console.error(error)
                return e
            }
        })
        return await Promise.all(modifiedRecord)
    
};


// const getOrgTypes =  (record) => {
//     const params = {org_id: record.organization_id}

//     return async () => {
        
//         try {
//             const response = await axios.get(CREATE_FILE.GET_ORG_OPTIONS, {params})
    
//             const options = response?.data?.options ?? []  
//             console.log(options, 'options');
//             const subTypes = options.find((d) => d.app_subtype_id === record.application_sub_type)?.app_subtype
//             console.log(
//             subTypes
//             );
//             return subTypes;
    
//         } catch (error) {
//             console.error(error)
    
//             // Show Alert
//             showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong')
//         }
//     }
// }

// Tranform Org Options
const transformOrgTypes = data => {
    // Transform data with app_type_id as key and app_type as value and app_subtype_id as key and app_subtype_id as value    
    if(data.length === 0) {
        return []
    }

    let transformedData = []
    let types = {}

    data.forEach(item => {
        if(!types[item.app_type_id]) {
            types[item.app_type_id] = {
                id: item.app_type_id,
                value: item.app_type,
                label: item.app_type,
                subTypes: [],
            }
        }
        types[item.app_type_id].subTypes.push({
            id: item.app_subtype_id,
            value: item.app_subtype_id,
            label: item.app_subtype,
            prefix: item.app_prefix
        })
    })

    for(let key in types) {
        transformedData.push(types[key])
    }
    
    return transformedData    
       
}

// Generate Loan Type Fields
const generateLoanTypeFields = (totalCoApp) => {

    let selectedOrgId =  store.getState().createFile.selectedOrg.id

    //  adding fixed static fields later for maintaining order
    //  applicant
    let fields = [];
    let oneBankOrgId = 29;

    // @desc: One Bank Loan have Property Varification Trip extra
    if(selectedOrgId === oneBankOrgId){
        fields.push( 
           {
               identifier: 'APPLICANT',
               type: 'APPLICANT',
               label: 'Applicant',
               title_postfix: 'Information',                    
               trips: [ 
                   { id: 1, identifier: 'HOUSE', type: 'HOUSE', label: 'House', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } },
                   { id: 2, identifier: 'OFFICE', type: 'OFFICE', label: 'Office', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } },
                   { id: 3, identifier: 'RENTAL', type: 'RENTAL', label: 'Rental', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } },
                   { id: 4, identifier: 'PROPERTY', type: 'PROPERTY', label: 'Property', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } }
               ],                                    
           })
       
   }else{
    fields.push(
        {
            identifier: 'APPLICANT',
            type: 'APPLICANT',
            label: 'Applicant',
            title_postfix: 'Information',                    
            trips: [ 
                { id: 1, identifier: 'HOUSE', type: 'HOUSE', label: 'House', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } },
                { id: 2, identifier: 'OFFICE', type: 'OFFICE', label: 'Office', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } },
                { id: 3, identifier: 'RENTAL', type: 'RENTAL', label: 'Rental', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } }
            ],                    
        }
    )
   }



    //  adding co-applicants fields dynamically as Co-Applicant is not fixed
    //  co-applicants
    for( let i = 0; i <totalCoApp; i++){
        fields.push( {
            identifier: `CO_APPLICANT${i+1}`,
            type : `CO_APPLICANT`,
            label : `Co-Applicant ${i+1}`,
            title_postfix: 'Information',                    
            trips : [                        
                { id: 1, identifier: 'HOUSE', type: 'HOUSE', label: 'House', concern: { label: `Co-Applicant ${i+1}`, type: `CO_APPLICANT${i+1}`, identifier: `CO_APPLICANT${i+1}`, } },
                { id: 2, identifier: 'OFFICE', type: 'OFFICE', label: 'Office', concern: { label: `Co-Applicant ${i+1}`, type: `CO_APPLICANT${i+1}`, identifier: `CO_APPLICANT${i+1}`, } },
            ],
            
        },)
    }

    //  adding fixed static fields later for maintaining order
    //  first guarantor
    //  second gurantor
    //  optional
    fields.push({
        identifier: 'FIRST_GUARANTOR',
        type : 'GUARANTOR',
        label : 'First Guarantor',
        title_postfix: 'Information',
        trips : [
            { id: 1, identifier: 'GUARANTOR_HOUSE', type: 'GUARANTOR_HOUSE', label: 'House', concern: { label: 'First Guarantor', type: 'GUARANTOR', identifier: 'FIRST_GUARANTOR', } },
            { id: 2, identifier: 'GUARANTOR_OFFICE', type: 'GUARANTOR_OFFICE', label: 'Office', concern: { label: 'First Guarantor', type: 'GUARANTOR', identifier: 'FIRST_GUARANTOR', } },
        ],
    },
    {
        identifier: 'SECOND_GUARANTOR',
        type : 'GUARANTOR',
        label : 'Second Guarantor',
        title_postfix: 'Information',                    
        trips : [                        
            { id: 1, identifier: 'GUARANTOR_HOUSE', type: 'GUARANTOR_HOUSE', label: 'House', concern: { label: 'Second Guarantor', type: 'GUARANTOR', identifier: 'SECOND_GUARANTOR', } },
            { id: 2, identifier: 'GUARANTOR_OFFICE', type: 'GUARANTOR_OFFICE', label: 'Office', concern: { label: 'Second Guarantor', type: 'GUARANTOR', identifier: 'SECOND_GUARANTOR', } },
        ],
    },
    {
        identifier: 'OPTIONAL',
        type : 'APPLICANT',
        label : 'Optional',
        title_postfix: 'Trips',                    
        trips : [
            { id: 1, identifier: 'FIRST_BANK', type: 'BANK', label: 'Bank', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'OPTIONAL', } },
            { id: 2, identifier: 'SECOND_BANK', type: 'BANK', label: 'Bank', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'OPTIONAL', } },
        ],
    })
    return fields
}

// Create Concerns and Trips
const createConcernsAndTrips = (application_type, coApplicantNo) => {
    let concernFields = [];

    // Check if application_type is valid
    if(!application_type) {
        return []
    }

    //   generating loan type fields-- as it has dynamic co-applicant -- so creating it on another function
    if(application_type){
        concernFields = generateLoanTypeFields(coApplicantNo)
    }

    switch(application_type) {


        case 1:
            return concernFields
           
        case 2:
            return [
                {
                    identifier: 'APPLICANT',
                    type : 'APPLICANT',
                    label : 'Applicant',
                    title_postfix: 'Information',                    
                    trips : [
                        { id: 1, identifier: 'HOUSE', type: 'HOUSE', label: 'House', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } },
                        { id: 2, identifier: 'OFFICE', type: 'OFFICE', label: 'Office', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } },
                    ],
                },
                {
                    identifier: 'OPTIONAL',
                    type : 'APPLICANT',
                    label : 'Optional',
                    title_postfix: 'Trips',
                    trips : [
                        { id: 1, identifier: 'FIRST_BANK', type: 'BANK', label: 'Bank', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'OPTIONAL', } },
                        { id: 2, identifier: 'SECOND_BANK', type: 'BANK', label: 'Bank', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'OPTIONAL', } },
                    ],
                }
            ]
        case 3:
            return [
                {
                    identifier: 'APPLICANT',
                    type : 'APPLICANT',
                    label : 'Applicant',
                    title_postfix: 'Information',                    
                    trips : [  
                        { id: 1, identifier: 'HOUSE', type: 'HOUSE', label: 'House', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } },                      
                        { id: 2, identifier: 'OFFICE', type: 'OFFICE', label: 'Office', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } },
                    ],                                        
                },
                {
                    identifier: 'FIRST_GUARANTOR',
                    type : 'GUARANTOR',
                    label : 'First Guarantor',
                    title_postfix: 'Information',
                    trips : [
                        { id: 1, identifier: 'GUARANTOR_HOUSE', type: 'GUARANTOR_HOUSE', label: 'House', concern: { label: 'First Guarantor', type: 'GUARANTOR', identifier: 'FIRST_GUARANTOR', } },
                        { id: 2, identifier: 'GUARANTOR_OFFICE', type: 'GUARANTOR_OFFICE', label: 'Office', concern: { label: 'First Guarantor', type: 'GUARANTOR', identifier: 'FIRST_GUARANTOR', } },
                    ],
                }
            ]
        case 4:
            return [
                {
                    identifier: 'APPLICANT',
                    type : 'APPLICANT',
                    label : 'Applicant',
                    title_postfix: 'Information',                    
                    trips : [
                        { id: 1, identifier: 'HOUSE', type: 'HOUSE', label: 'House', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } },
                        { id: 2, identifier: 'OFFICE', type: 'OFFICE', label: 'Office', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'APPLICANT', } },
                    ],
                },
                {
                    identifier: 'OPTIONAL',
                    type : 'APPLICANT',
                    label : 'Optional',
                    title_postfix: 'Trips',
                    trips : [
                        { id: 1, identifier: 'FIRST_BANK', type: 'BANK', label: 'Bank', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'OPTIONAL', } },
                        { id: 2, identifier: 'SECOND_BANK', type: 'BANK', label: 'Bank', concern: { label: 'Applicant', type: 'APPLICANT', identifier: 'OPTIONAL', } },
                    ],
                }
            ]
        default:
            return []
    }

}

// if there is a bank trip it will be added under APPLICANT's trip array list
const validateApplicantBankTrip = (concerns) => {
    // filtering out null/undefined forms
    const removedNullConcerns = concerns.filter(item => item)
    let mergedConcern = {}

    removedNullConcerns.forEach((item, index) => {
        // excluding GUARANTOR since it doesn't have any BANK trip and also there is two guarantor and both has same type that is type='GUARANTOR'
        // item?.type is considered object key for respective concern 
        if((item?.type !== 'CO_APPLICANT') && (item?.type !== 'GUARANTOR')){
            mergedConcern = {
                ...mergedConcern,
                [item.type] : {
                    ...item,
                    ...mergedConcern[item.type],
                    trips: mergedConcern[item.type]?.trips?.length > 0 ? [...mergedConcern[item.type]?.trips, ...item?.trips] : item?.trips
                }
            }
            return
        }

        if((item?.type == 'CO_APPLICANT')){
            mergedConcern = {
                ...mergedConcern,
                [`CO_APPLICANT-${index}`]: item
            }
        }

        // Adding guarantor's trip inside object's property. Here index to make it unique so that multiple guarantor doesn't replace other's data
        if((item?.type == 'GUARANTOR')){
            mergedConcern = {
                ...mergedConcern,
                [`GUARANTOR-${index}`]: item
            }
        }


    
    })

    // filtering only concern data by removing key's of mergedConcern | This key was added just to merge all trips of same concern in single key 
    const newConcerns = []
    for (const [key, value] of Object.entries(mergedConcern)) {
        newConcerns.push(value)
      }

    return newConcerns
}

// remove concerns with empty information ( TO MAINTAIN OPEN FORM COLLAPSIBLE ISSUE )
const removeEmptyConcern = (concernData) => {
    const newValuesObj = {}
    const concernCopy = {...concernData}
    const keys = Object.keys(concernCopy)


    keys.forEach(key => {
        
        const keyInLowercase = key.toLowerCase()
        
        // checking the existance of data of group_uuid, application and APPLICANT  is not required here because it is done in other steps of create file cycle
        if(keyInLowercase !== 'group_uuid' && keyInLowercase !== 'application' && keyInLowercase !== 'applicant' ){
            if(!concernCopy[key]?.name){
                // showAlert('error', `Please fill Name of ${key}.`)
                return
            }
            else{
                // copying concern data that has name
                newValuesObj[`${key}`] =  concernCopy[key]
            }
        }
         // copying concern data
        newValuesObj[`${key}`] =  concernCopy[key]

    })
    
    return concernCopy
}

// Concern Validation
const validateConcern = values => {
    return ( dispatch, getState ) => {
        const { selectedOption } = getState().createFile
        const { coApplicantNo } = getState().createFile

        // Check if selected option of organizations is not valid
        if(!selectedOption && !selectedOption.id) {
            return
        }
        // Get Concerns and Trips
        const selectedConcernAndTrips = createConcernsAndTrips(selectedOption.id, coApplicantNo)        
        // Check if selectedConcernAndTrips is not valid and length is 0
        if(!selectedConcernAndTrips || selectedConcernAndTrips.length === 0) {
            return
        }        

        // removing concerns that have no Name and trips information - 
        // if we open the collapsible form in dahsboard, the form will send value onFinish even if the form is empty (Open Concern Issue)
        const concernWithInformation = removeEmptyConcern(values)
        // console.log(concernWithInformation, 'concernWithInformation')
        const _tripsConcernWise = selectedConcernAndTrips.map(item => {
            // Check if item type not valid or item === 'application'
            if(!item.type || item.type === 'application') {
                return null
            }

            const trips = item.trips.map(trip => {
                // Check if both given address and exact address is not valid
                if(!concernWithInformation[item.identifier]?.[trip.identifier]?.address && !concernWithInformation[item.identifier]?.[trip.identifier]?.exact_address) {
                    // Show error alert
                    // showAlert('error', `Please validate exact address of ${item.label} > ${trip.label}.`)
                    // return null
                }
                // Check if given address is not valid
                if(!concernWithInformation[item.identifier]?.[trip.identifier]?.address) {
                    // Show error alert
                    // showAlert('error', `Please fill ${item.label} ${trip.label} trips address field properly.`)
                    // return null
                }

                // Check if exact address is not valid
                if(!concernWithInformation[item.identifier]?.[trip.identifier]?.exact_address) {
                    // Show error alert
                    // showAlert('error', `Please fill ${item.label} ${trip.label} trips exact address field properly.`)
                    // return null
                }

                // if address, exact_address, latitude, longititude are available
                if(concernWithInformation[item.identifier]?.[trip.identifier]?.address 
                    && concernWithInformation[item.identifier]?.[trip.identifier]?.exact_address 
                    && concernWithInformation[item.identifier]?.[trip.identifier]?.latitude 
                    && concernWithInformation[item.identifier]?.[trip.identifier]?.longitude) {                    
                   // extracting the valid trip 
                    const trip_details = transformTripData(concernWithInformation[item.identifier]?.[trip.identifier], trip.type)     
                    return trip_details
                }
                return null

     
            })
            .filter(trip => trip) 

            if(concernWithInformation[item.identifier]) {
                return concernWithInformation[item.identifier] = {
                    name: concernWithInformation[item.identifier]?.name ?? '',
                    phone: concernWithInformation[item.identifier]?.phone ?? '',
                    spouse: concernWithInformation[item.identifier]?.spouse ?? '',
                    designation: concernWithInformation[item.identifier]?.designation ?? '',
                    image: concernWithInformation[item.identifier]?.image ?? '',
                    type: item.type,
                    trips
                }
            }
            return null
        })

        // merge bank trips inside Applicant's trips and filtering null/indefined forms
        const mergedConcernDetailsWithbank = validateApplicantBankTrip(_tripsConcernWise)

        // filter out object without name and trip[s] 
        const mergedConcernDetails = mergedConcernDetailsWithbank?.filter(item => (item?.name && item?.trips?.length > 0) || (item?.type === 'APPLICANT' && item?.name))

        // Check if mergedConcernDetails is not valid and length is 0
        if(!mergedConcernDetails || mergedConcernDetails.length === 0) {
            return
        }

        // Check if concern information is missing
        const _isNameEmpty = mergedConcernDetails.some(item => !item.name)

        // Check if _isNameEmpty is true
        if(_isNameEmpty) {
            // Show error alert
            showAlert('error', 'Please fill concern information field properly.')
            return
        }

        // Check if trips information is missing
        const _isTripsEmpty = mergedConcernDetails.some(item => item.trips?.length === 0)

        // Check if _isTripsEmpty is true
        if(_isTripsEmpty) {
            // Show error alert
            showAlert('error', 'Please fill trips information field properly.')
            return
        }


        return ({
            group_uuid: concernWithInformation.group_uuid,
            application: {
                ...concernWithInformation.application,
                urgent_tag: concernWithInformation.application?.urgent_tag ?? 0,
            }, 
            concerns: mergedConcernDetails 
        })
    }
}

// Tansform trip data
const transformTripData = (trip, type) => {    
    // Check if trip is not valid
    if(!trip) {
        return
    }
    const trip_details = {
        type,
        place_name: trip.placename ?? '',
        address: trip.address,
        exact_address: trip.exact_address,
        is_address_exact: trip.not_exact ? 0 : 1,
        area: trip.area ?? '',
        city: trip.city ?? '',
        latitude: trip.latitude,
        longitude: trip.longitude,        
        comment: trip.comment ?? '',        
    }

    // Check if trip type is 'BANK'
    if(type === 'BANK') {
        trip_details.bank = {
            name: trip.bank_name,
            branch: trip?.bank_branch_name ?? '',
            account_number: trip.account_number,
            account_title: trip.account_title,
        }
    }
    return trip_details
}

// Create new file
const createNewFile = (data, files, clearForm, ocr_id) => {
    const formData = new FormData()
    
    formData.append('group_uuid', data.group_uuid)
    if (ocr_id) {
        formData.append('ocr_id', ocr_id)
    }

    Object.keys(data.application).forEach((key) => {    
        formData.append(`application[${key}]`, data.application[key])
    })

    data.concerns.forEach((c, i) => {
        Object.keys(c).forEach((key) => {
            if (key === "trips") {
            c.trips.forEach((t, ti) => {
                Object.keys(t).forEach((tKey) => {
                    if (tKey === "bank") {
                        Object.keys(t[tKey]).forEach((tBankKey) => {                
                            formData.append( `concerns[${i}][${key}][${ti}][${tKey}][${tBankKey}]`, t[tKey][tBankKey] )
                        })
                    } else {                
                        formData.append(`concerns[${i}][${key}][${ti}][${tKey}]`, t[tKey])
                    }
                })
            })
            } else {    
                formData.append(`concerns[${i}][${key}]`, c[key])
            }
        })
    })

    // Files
    const images = [...files]
    images.forEach((file, index) => {
        formData.append(`loi[${index}]`, file)
    })
    
    return async (dispatch) => {
        // Set is loading to true
        dispatch(setIsLoading(true))
        try {
            const response = await axios.post(CREATE_FILE.CREATE_NEW_FILE, formData)

            // Check if response is valid
            if(response.data.status === 200) {
                // Set is loading to false
                dispatch(setIsLoading(false))

                // Set Reset to true
                dispatch(setIsResetClicked(true))

                // Set Reset to false
                setTimeout(() => {
                    dispatch(setIsResetClicked(false))
                } , 200)

                // Clear form
                clearForm()

                // Clear Marker Data
                dispatch(setMarkerData([]))
                getOCRList()(dispatch)
                // Show success alert
                showAlert('success', response.data.message)  
                return response.data              
            } else {
                // Set is loading to false
                dispatch(setIsLoading(false))

                // Show error alert
                showAlert('error', response?.data?.message ?? 'Something went wrong')
            }

        } catch(error) {
            console.error(error)

            // Set is loading to false
            dispatch(setIsLoading(false))

            // Show error alert
            showAlert('error', error.response.data.message ?? error?.message ?? 'Something went wrong')            
        }
    }
}

const createFileByBulkUpload = (params) => {
    return dispatch => {
        dispatch(setIsFileUploading(true))
        axios.post( CREATE_FILE.BULK_UPLOAD, params )
        .then(res => {
            // console.log(res, 'res')
            if(res?.status !== 200){
                dispatch(setIsFileUploading(false))
                showAlert('error', res?.response?.data?.message ?? res?.message ?? 'Something went wrong')
                return
            }
           
            dispatch(setIsFileUploading(false))
            showAlert('success', res?.data?.message ? res.data.message : 'File Upload Successfull')  
            
        })
        .catch(err => {
            // console.log(err?.response, 'err')
            dispatch(setIsFileUploading(false))
            // Error Message
            if(err?.response?.data?.errors){
                if(Object.keys(err?.response?.data?.errors).length > 0){
                    Object.entries(err?.response?.data?.errors).forEach(([key, value]) => {
                        showAlert('error', value) 
                    })
                }
            } else {
                showAlert('error', err?.response?.data?.message ?? err?.message ?? 'File Upload Failed') 
            }
        })
    }
}


// add name key to each object in orcList array by matching with the uuid==orgid in orgList array
const transformORCList = (ocrList, orgList) => {
    // console.log(ocrList, 'orcList');
    // console.log(orgList, 'orgList');
    return ocrList.map(ocr => {
        const org = orgList?.find(org => org?.uuid === ocr?.organization_id)
        return {
            ...ocr,
            name: org?.name ?? ''
        }
    })
}

// Get OCR List 
const getOCRList = () => {
    return async dispatch => {
        dispatch(setIsOCRLoading(true))
        try {
            const response = await axios.get(CREATE_FILE.GET_OCR_LIST)
            const OCR_List = response?.data?.data?.ocrs ?? []
            const orgWithTypes = await getOrgTypes(OCR_List)
            // Set OCR List
            dispatch(setOCRList(orgWithTypes))
            dispatch(setIsOCRLoading(false))
            
        } catch (error) {
            console.error(error)

            // Set isLoading to false
            dispatch(setIsOCRLoading(false))

            // Show Alert
            showAlert('error', error?.response?.data?.message ?? error?.message ?? 'Something went wrong')
        }
    }
}

const updateOCRFileStatus = (ocrData) => {
    return async (dispatch) => {
        axios.post(CREATE_FILE.UPDATE_OCR_STATUS + `/${ocrData?.id}?application_id=${ocrData?.application_number}`)
        .then(res => {
            if(res?.status !== 200){
                showAlert('error', res?.response?.data?.message ?? res?.message ?? 'Something went wrong')
                return
            }
            getOCRList()(dispatch)
        })
        .catch(err => {
            // console.log(err?.response, 'err')
            // Error Message
            if(err?.response?.data?.errors){
                if(Object.keys(err?.response?.data?.errors).length > 0){
                    Object.entries(err?.response?.data?.errors).forEach(([key, value]) => {
                        showAlert('error', value) 
                    })
                }
            } else {
                showAlert('error', err?.response?.data?.message ?? err?.message ?? 'Something went wrong') 
            }
        })
    }
}

export { getOrgOptions, createConcernsAndTrips, validateConcern, createNewFile, createFileByBulkUpload , getOCRList , transformORCList, getOrgTypes, updateOCRFileStatus}