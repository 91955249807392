import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    appStatistics: {
        isLoading: false,
        statistics: [],
    },
    tripsStatistics: {
        isLoading: false,
        statistics: [],
    },
    tripsStatisticsByType: {
        isLoading: false,
        statistics: [],
    },
    issuesStatistics: {
        isLoading: false,
        statistics: [],
    },
    remarksStatistics: {
        isLoading: false,
        statistics: [],
    },
    agentAllocatedTripsStatistics: {
        isLoading: false,
        statistics: [],
    },
    agentRaisedIssuesStatistics: {
        isLoading: false,
        statistics: [],
    },
    organizationApplicationsStatistics: {
        isLoading: false,
        statistics: [],
        error: null
    }
}

const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setApplicationStatistics: (state, action) => {
            state.appStatistics = action.payload
        },
        setTripsStatistics: (state, action) => {
            state.tripsStatistics = action.payload
        },
        setTripsStatisticsByType: (state, action) => {
            state.tripsStatisticsByType = action.payload
        },
        setIssuesStatistics: (state, action) => {
            state.issuesStatistics = action.payload
        },
        setRemarksStatistics: (state, action) => {
            state.remarksStatistics = action.payload
        },
        setAgentAllocatedTripsStatistics: (state, action) => {
            state.agentAllocatedTripsStatistics = action.payload
        },
        setAgentRaisedIssuesStatistics: (state, action) => {
            state.agentRaisedIssuesStatistics = action.payload
        },
        setOrganizationApplicationsStatistics: (state, action) => {
            state.organizationApplicationsStatistics = action.payload
        }
    }
})

export const { setApplicationStatistics, setTripsStatistics, setTripsStatisticsByType, setIssuesStatistics, setRemarksStatistics, setAgentAllocatedTripsStatistics, setAgentRaisedIssuesStatistics, setOrganizationApplicationsStatistics } = analyticsSlice.actions
export default analyticsSlice.reducer