import axios from 'axios'

// Import Types
import { ANALYTICS } from '../../Config/config'

// Import Actions
import { setApplicationStatistics, setTripsStatistics, setTripsStatisticsByType, setIssuesStatistics, setRemarksStatistics, setAgentAllocatedTripsStatistics, setAgentRaisedIssuesStatistics, setOrganizationApplicationsStatistics } from '../Reducers/analyticsReducer'

// Get Application Statistics
const getApplicationStatistics = ( params ) => {
    return async (dispatch) => {
        dispatch(setApplicationStatistics({ isLoading: true }))
        try {
            const response = await axios.get(ANALYTICS.APP_STATISTICS, { params })
            const data = response?.data?.data
            const transformedData = [{
                key: 1,
                total_applications: data?.total_applications ?? '-',
                running_applications: data?.application_status?.running?.total ?? '-',
                finish_applications: data?.application_status?.finished?.total ?? '-',
                loan_applications: data?.applications_type?.loan?.total ?? '-',
                card_applications: data?.applications_type?.card?.total ?? '-', 
                finished_in_one_go: data?.finished_in_one_go ?? '-',
                finished_percentage: data?.finish_percentage ?? '-',
            }]

            dispatch(setApplicationStatistics({ statistics: transformedData, isLoading: false }))
            
        } catch (error) {
            dispatch(setApplicationStatistics({ isLoading: false }))
            // dispatch(setError(error.response.data.message))
        }
    }
}

// Get Trips Statistics
const getTripsStatistics = ( params ) => {
    return async (dispatch) => {
        dispatch(setTripsStatistics({ isLoading: true }))
        try {
            const response = await axios.get(ANALYTICS.TRIPS_STATISTICS, { params })
            const data = response?.data?.data
            const transformedData = [{
                key: 1,
                total_trips: data?.total_trip ?? '-',
                running_trips: data?.trips?.running?.total ?? '-',
                not_accepted_trips: data?.trips?.not_accepted?.total ?? '-',
                finished_trips: data?.trips?.finished?.total ?? '-',
                finished_in_one_go_trips: data?.finished_in_one_go ?? '-', 
                finish_percentage: data?.finish_percentage ?? '-',
            }]

            dispatch(setTripsStatistics({ statistics: transformedData, isLoading: false }))
            
        } catch (error) {
            dispatch(setTripsStatistics({ isLoading: false }))
            // dispatch(setError(error.response.data.message))
        }
    }
}

// Get Trips Statistics By Type
const getTripsStatisticsByType = ( params ) => {
    return async (dispatch) => {
        dispatch(setTripsStatisticsByType({ isLoading: true }))
        try {
            const response = await axios.get(ANALYTICS.COMPLETED_TRIP_STATISTICS, { params })
            const data = response?.data?.data?.trip_completed            
            const transformedData = data?.length > 0 ? data.map((item, index) => (
                {
                    key: index + 1,
                    type: item?.type ?? '-',
                    trip_type: item?.trip_type ?? '-',
                    total_trips: item?.total ?? '-',
                }
            )) : []

            dispatch(setTripsStatisticsByType({ statistics: transformedData, isLoading: false }))
            
        } catch (error) {
            dispatch(setTripsStatisticsByType({ isLoading: false }))
            // dispatch(setError(error.response.data.message))
        }
    }
}

// Get Issues Statistics
const getIssuesStatistics = ( params ) => {
    return async (dispatch) => {
        dispatch(setIssuesStatistics({ isLoading: true }))
        try {
            const response = await axios.get(ANALYTICS.ISSUES_STATISTICS, { params })
            const data = response?.data?.data?.issues
            const transformedData = data?.length > 0 ? data.map((item, index) => (
                {
                    key: index + 1,
                    issue: item?.issue ?? '-',
                    total: item?.total ?? '-',
                }
            )) : []

            dispatch(setIssuesStatistics({ statistics: transformedData, isLoading: false }))

        } catch (error) {
            dispatch(setIssuesStatistics({ isLoading: false }))
            // dispatch(setError(error.response.data.message))
        }
    }
}

// Get Remarks Statistics
const getRemarksStatistics = ( params ) => {
    return async (dispatch) => {
        dispatch(setRemarksStatistics({ isLoading: true }))
        try {
            const response = await axios.get(ANALYTICS.REMARKS_STATISTICS, { params })
            const data = response?.data?.data?.remarks
            const transformedData = data?.length > 0 ? data.map((item, index) => (
                {
                    ...item,
                    key: index + 1,
                }
            )) : []

            dispatch(setRemarksStatistics({ statistics: transformedData, isLoading: false }))

        } catch (error) {
            dispatch(setRemarksStatistics({ isLoading: false }))
            // dispatch(setError(error.response.data.message))
        }
    }
}

// Get Agent Allocated Trips Statistics
const getAgentAllocatedTripsStatistics = ( params ) => {
    return async (dispatch) => {
        dispatch(setAgentAllocatedTripsStatistics({ isLoading: true }))
        try {
            const response = await axios.get(ANALYTICS.AGENT_ALLOCATED_TRIP_STATISTICS, { params })
            const data = response?.data?.data?.analytics

            // create an new array unique by name with the count of trips type and total            
            const transformedData = getUniqueArrayOfAgentAllocatedTrips(data)
            dispatch(setAgentAllocatedTripsStatistics({ statistics: transformedData, isLoading: false }))

        } catch (error) {
            dispatch(setAgentAllocatedTripsStatistics({ isLoading: false }))
            // dispatch(setError(error.response.data.message))
        }
    }
}

// create an new unique array object from data by name with the count of trips type and total
const getUniqueArrayOfAgentAllocatedTrips = (data) => {
    const uniqueNames = [...new Set(data?.map(item => item.name))]    
    const uniqueArray = uniqueNames.map((name, index) => {
        const obj = {
            key: index + 1,
            name,
            total_allocated: 0,
            total_finished: 0,
            trips: []
        }
        data.forEach(item => {
            if (item.name === name) {
                obj.total_allocated += typeof item.total_allocated === 'string' ? Number(item.total_allocated) : item.total_allocated
                obj.total_finished += typeof item.total_finished === 'string' ? Number(item.total_finished) : item.total_finished
                obj.trips.push(item)
            }
        })
        const updatedObj = {
            ...obj,
            trips: getUniqueArrayByTypeOfAgentAllocatedTrips(obj?.trips ?? [])
        }        
        return updatedObj
    })
    return uniqueArray
}

// create a new array object from data by trip type from an array with the count of trips type and total
const getUniqueArrayByTypeOfAgentAllocatedTrips = (data) => {
    // console.log(data, 'getUniqueArrayByTypeOfAgentAllocatedTrips');
    const trip_types = [ 'BANK', 'CAR_QUOTATION', 'DOCUMENT', 'GUARANTOR_HOUSE', 'GUARANTOR_OFFICE', 'HOUSE', 'OFFICE', 'RENTAL' ]
    const uniqueArray = trip_types.map(type => {
        const obj = {
            type,
            total_allocated: 0,
            total_finished: 0            
        }

        data.forEach(item => {
            if (item.type === type) {
                obj.total_allocated += typeof item.total_allocated === 'string' ? Number(item.total_allocated) : item.total_allocated
                obj.total_finished += typeof item.total_finished === 'string' ? Number(item.total_finished) : item.total_finished             
            }
        })        
        return obj
    })
    return uniqueArray
}

// Get Agent Raised Issues Statistics
const getAgentRaisedIssuesStatistics = ( params ) => {
    return async (dispatch) => {
        dispatch(setAgentRaisedIssuesStatistics({ isLoading: true }))
        try {
            const response = await axios.get(ANALYTICS.AGENT_RAISED_ISSUES_STATISTICS, { params })
            const data = response?.data?.data?.issues_count
            const transformedData = data?.length > 0 ? data.map((item, index) => (
                {                    
                    key: index + 1,
                    name: item?.user?.name ?? '-',
                    total: item?.total_issues ?? '-',
                }
            )) : []            

            dispatch(setAgentRaisedIssuesStatistics({ statistics: transformedData, isLoading: false }))
        }
        catch (error) {
            dispatch(setAgentRaisedIssuesStatistics({ isLoading: false }))
            // dispatch(setError(error.response.data.message))
        }
    }
}

// Get Organization Applications Statistics
const getOrganizationApplicationsStatistics = ( params ) => {
    return async (dispatch) => {
        dispatch(setOrganizationApplicationsStatistics({ isLoading: true, statistics: [], error: null }))
        try {
            const response = await axios.get(ANALYTICS.ORGANIZATION_APPLICATIONS_STATISTICS, { params })
            const data = response?.data?.data?.organizations ?? []

            dispatch(setOrganizationApplicationsStatistics({ statistics: data, isLoading: false, error: null }))
        }
        catch (error) {
            const errorMessage = error?.response?.data?.message || "An error occurred";
            dispatch(setOrganizationApplicationsStatistics({ 
                isLoading: false, 
                statistics: [], 
                error: errorMessage 
            }))
            // dispatch(setError(error.response.data.message))
        }
    }
}

export { getApplicationStatistics, getTripsStatistics, getTripsStatisticsByType, getIssuesStatistics, getRemarksStatistics, getAgentAllocatedTripsStatistics, getAgentRaisedIssuesStatistics, getOrganizationApplicationsStatistics }