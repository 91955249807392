import React from "react";
import PropTypes from "prop-types";
import _isEqual from "fast-deep-equal";
import { connect } from "react-redux";
import moment from "moment";

// Import Library Components
import { REPORTS } from "../Config/config";
import { Card, Table, Tag, Space, Button, Tooltip, List, Modal } from "antd";
import {
  EyeOutlined,
  FileTextOutlined,
  DeleteOutlined,
  LoadingOutlined,
  DownloadOutlined,
  CheckOutlined,
} from "@ant-design/icons";

// Import Reducer
import {
  setSelectedApplication,
  setApplicationTopsheet,
} from "../Services/Reducers/reportsReducer";

// Import Actions
import {
  getReportsByDateRange,
  getReportsByInput,
  downloadSLAReport,
  openStatusUpdateModal,
  downloadBankReport,
  deleteApplication,
  downloadTripStatusReport,
  activateStatusSocketConnection,
  downloadExecutiveReport,
} from "../Services/Actions/reportsActions";
import { downloadTopsheet } from "../Services/Actions/reportsActions";
import { showAlert } from "../Services/Actions/commonActions";
import { isAllowedToAccess } from "../Services/Actions/permissionActions";

// Import Component
import FilterWithDateOrg from "../Components/Layouts/FilterWithDateOrg";
import { UpdateTopsheet } from "../Components/Layouts/Reports";
import TopsheetDownloadButton from "../Components/Layouts/Reports/TopsheetDownloadButton";
import StatusUpdateModal from "../Components/Layouts/Reports/StatusUpdateModal";
import BankDownloadButton from "../Components/Layouts/Reports/BankDownloadButton";

const { confirm } = Modal;

class Reports extends React.PureComponent {
  state = {
    columns: null,
    startDate: null,
    endDate: null,
    selectedGroup: null,
    selectedCity: null,
    selectedVerifier: null,
    selectedStatus: null,
    inputValue: "",
    visible: false,
    loading: false,
    openingApplicationID: 0,
    file_no: 0,
    currentApplicationData: null,
    currentFilterData: {},
    isSearch: false,
    page: 1,
    sorterOrder: 'ASC',
    selectedSortBy: null
  };

  componentDidMount = () => {
    const { dispatch } = this.props;
    const columnHeader = this._getTableColumnHeader();
    this.setState({ columns: columnHeader });
    this._handleSubmit();

    dispatch(activateStatusSocketConnection());
  };
  componentDidUpdate = (prevProps) => {
    const { allowedPermission, socketStatus, pagination } = this.props;
    const { isSearch } = this.state;
    if (!_isEqual(prevProps.allowedPermission, allowedPermission)) {
      this.setState({
        columns: this._getTableColumnHeader(),
      });
    }

    // console.log(isSearch, 'isSearch')
    // on socket status update get reports
    // if (!_isEqual(prevProps.socketStatus, socketStatus) && !isSearch) {
    //   setTimeout(this._getReports(pagination.current_page, true, false), 3000);
    // }
  };

  // Download Topsheet
  _downloadApplicationTopSheet = (e, record) => {
    const { dispatch } = this.props;
    dispatch(downloadTopsheet(record.id, record.file_no));
  };

  // generating table column header
  _getTableColumnHeader = () => {
    const { allowedPermission, user } = this.props;
    // console.log(user, 'user')
    const columnHeaderData = [
      {
        title: "Serial No",
        dataIndex: "key",
        key: "key",
        fixed: "left",
        render: (keys, record) => {
          const { page } = this.state;
          if (page > 1) {
            return (page - 1) * 50 + keys;
          } else {
            return keys;
          }
        },
      },
      {
        title: "Applicant Name",
        dataIndex: "applicant_name",
        key: "applicant_name",
        fixed: "left",
      },
      {
        title: "File Number",
        dataIndex: "file_no",
        key: "file_no",
        fixed: "left",
      },
      {
        title: "File Status",
        dataIndex: "status",
        key: "status",
        render: ({ label, color }) => <Tag color={color}>{label}</Tag>,
      },
      {
        title: "Attachments",
        dataIndex: "attachments",
        key: "attachments",
        render: (text, record) =>
          // [NOTE: This is a Static Action] this status is forced to render Clear if Submitted option selected from update status modal
          record?.status?.value === 6 ? (
            <Tag color={"#35AA80"}>{"Clear"}</Tag>
          ) : (
            <Tag color={text.color}>{text.label}</Tag>
          ),
      },
      {
        title: "Org Name",
        dataIndex: "org_name",
        key: "org_name",
      },
      {
        title: "Trip IDs",
        dataIndex: "trips",
        key: "trips",
        render: (trips, record) =>
        (<div style={{
          width: '150px',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '2px'
        }}>
          {trips?.length > 0 ? (
            trips.map((trip) => {
              const text = `${trip.trip_id}`

              return (
                <span
                  key={trip.trip_id}
                  style={{
                    padding: "1px",
                    paddingLeft: "6px",
                    paddingRight: "5px",
                    marginRight: "3px",
                    borderRadius: "4px",
                    color: trip?.trip_issues ? "red" : "white",
                    fontWeight: trip?.trip_issues ? "bold" : "",
                    backgroundColor: trip?.trip_issues
                      ? trip?.trip_status?.color
                      : trip?.trip_status?.color,
                    cursor: trip?.trip_issues ? "pointer" : "pointer",
                  }}
                  onClick={(e) => this._openApplicationPage(e, record)}
                >
                  {trip?.trip_issues ? (
                    <Tooltip
                      color={"red"}
                      title={
                        <div>
                          <List
                            size={"small"}
                            dataSource={trip?.issues ?? []}
                            renderItem={(item, index) => (
                              <List.Item style={{ color: "#FFF" }}>
                                {`${index + 1}. ${item.issue}`}
                              </List.Item>
                            )}
                          />
                        </div>
                      }
                    >
                      {text}
                    </Tooltip>
                  ) : (
                    text
                  )}
                </span>
              );
            })
          ) : (
            <span>-</span>
          )}
        </div>)

      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        key: "start_date",
      },
      {
        title: "SLA",
        dataIndex: "sla",
        key: "sla",
        render: ({ sla_date, color }) => (
          <span style={{ color }}>{sla_date}</span>
        ),
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        key: "end_date",
        defaultSortOrder: 'descend',
        sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
      },
      {
        title: "Actions",
        key: "actions",
        render: (text, record) => {
          return (
            <Space direction={"horizontal"} size={"small"}>
              {[
                // PERMISSION ID OF "reports.reports.file.show" is 12
                isAllowedToAccess(allowedPermission, {
                  menuName: "reports",
                  subMenuName: "reports",
                  permissionID: "12",
                }) && (
                  <Tooltip
                    title={"View Application"}
                    placement={"topRight"}
                    key={"view-application"}
                  >
                    <Button
                      icon={<EyeOutlined />}
                      size={"small"}
                      onClick={(e) => this._openApplicationPage(e, record)}
                    />
                  </Tooltip>
                ),

                // PERMISSION ID OF "reports.reports.file.bs_download" is 16
                isAllowedToAccess(allowedPermission, {
                  menuName: "reports",
                  subMenuName: "reports",
                  permissionID: "16",
                }) && (
                  <Tooltip
                    title={"Download Bank Report"}
                    placement={"topRight"}
                    key={"download-bank-report"}
                  >
                    {''}
                    <BankDownloadButton application={record} />
                  </Tooltip>
                ),

                // PERMISSION ID OF "reports.reports.file.top_sheet_download" is 17
                isAllowedToAccess(allowedPermission, {
                  menuName: "reports",
                  subMenuName: "reports",
                  permissionID: "17",
                }) && (
                  <Tooltip
                    title={"Download Topsheet"}
                    placement={"topRight"}
                    key={"download-topsheet"}
                  >
                    {''}
                    <TopsheetDownloadButton
                      icon={<DownloadOutlined />}
                      size={"small"}
                      downloadTopsheet={(e) =>
                        this._downloadApplicationTopSheet(e, record)
                      }
                      record={record}
                      disabled={(record?.is_download_enabled === 0) ? true : false}
                    />
                  </Tooltip>
                ),

                // PERMISSION ID OF "reports.reports.file.top_sheet_update" is 15
                isAllowedToAccess(allowedPermission, {
                  menuName: "reports",
                  subMenuName: "reports",
                  permissionID: "15",
                }) && (
                  <Tooltip
                    title={"Update Topsheet"}
                    placement={"topRight"}
                    key={"update-topsheet"}
                  >
                    <Button
                      icon={<FileTextOutlined />}
                      size={"small"}
                      onClick={() => this._showModal(record)}
                    />
                  </Tooltip>
                ),

                // PERMISSION ID OF "reports.reports.file.delete" is 13
                isAllowedToAccess(allowedPermission, {
                  menuName: "reports",
                  subMenuName: "reports",
                  permissionID: "13",
                }) && (
                  <Tooltip
                    title={"Delete Application"}
                    placement={"topRight"}
                    key={"delete-file"}
                  >
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size={"small"}
                      onClick={() => this._handleModal(record)}
                    />
                  </Tooltip>
                ),

                // PERMISSION ID OF "reports.reports.file.update" is 14
                isAllowedToAccess(allowedPermission, {
                  menuName: "reports",
                  subMenuName: "reports",
                  permissionID: "14",
                }) && (
                  <Tooltip
                    title={"Update Status"}
                    placement={"topRight"}
                    key={"file-status-update"}
                  >
                    <Button
                      icon={<CheckOutlined />}
                      size={"small"}
                      onClick={(e) => this._handleStatusUpdate(e, record)}
                      // only allowing file status with =>ongoing"2 / hold: 3 / Finish: 4 / Submitted: 6 / completed: 7
                      disabled={
                        record.status.value !== 2 &&
                        record.status.value !== 3 &&
                        record.status.value !== 4 &&
                        record.status.value !== 6 &&
                        record.status.value !== 7
                      }
                    />
                  </Tooltip>
                ),
              ]}
            </Space>
          );
        },
      },
    ];
    return columnHeaderData;
  };

  // Handle on submit
  _handleSubmit = (
    startDate,
    endDate,
    selectedGroup,
    selectedCity,
    selectedStatus,
    selectedVerifier,
    selectedSortBy
  ) => {
    this.setState({
      startDate,
      endDate,
      selectedGroup,
      selectedCity,
      selectedVerifier,
      selectedStatus,
      inputValue: "",
      selectedSortBy
    });
    setTimeout(() => {
      this._getReports(1, false, 1);
    }, 100);

    this.setState({ isSearch: false });
    this.setState({ page: 1 });
  };

  //Get Executive reports params
  _getExecutiveParams = (page) => {
    const { startDate, endDate, selectedGroup, selectedCity, selectedVerifier, selectedStatus, sorterOrder, selectedSortBy } =
      this.state;

    // transform startDate EndDate
    const formatedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");
    const formatedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");

    // set params
    let params = {
      date_from: formatedStartDate,
      date_to: formatedEndDate,
      page: page ?? 1,
      sort_by: 'start_date', // 'start_date' or 'end_date',
      sort_order: sorterOrder ?? ''
    };

    // if not restricted and changed option from drowpdown
    if (
      selectedGroup &&
      selectedGroup?.length > 0
    ) {
      params = { ...params, org_id: selectedGroup };
    }
    return params;
  };

  // Get Params
  _getParams = (page) => {
    const { allowedPermission, user } = this.props;
    const { startDate, endDate, selectedGroup, selectedCity, selectedVerifier, selectedStatus, sorterOrder, selectedSortBy } =
      this.state;

    // transform startDate EndDate
    const formatedStartDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");
    const formatedEndDate = endDate
      ? moment(endDate).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");

    // set params
    let params = {
      dateFrom: formatedStartDate,
      dateTill: formatedEndDate,
      page: page ?? 1,
      sort_by: this.state.selectedSortBy?.value === 'created_at' ?
        'start_date' : 'end_date', // 'start_date' or 'end_date',
      sort_order: sorterOrder ?? ''
    };

    // if org filter is restricted. we show  only that specific org data as default
    // PERMISSION ID OF "reports.reports.filter.organization" is 8
    if (
      !isAllowedToAccess(allowedPermission, {
        menuName: "reports",
        subMenuName: "reports",
        permissionID: "8",
      })
    ) {
      params = { ...params, org_id: user?.org_id ?? null };
    }

    // if not restricted and changed option from drowpdown
    if (
      selectedGroup &&
      selectedGroup?.length > 0
    ) {
      params = { ...params, org_id: selectedGroup };
    }

    if (selectedCity && selectedCity?.id !== -1) {
      params = { ...params, city: selectedCity.value };
    }

    if (
      selectedVerifier &&
      selectedVerifier?.length > 0
    ) {
      params = { ...params, agent_id: selectedVerifier };
    }

    if (selectedStatus && selectedStatus?.length > 0) {
      params = { ...params, status: selectedStatus };
    }

    // console.log(params, 'params')
    return params;
  };

  // Get Reports
  _getReports = (page, key, isShort) => {
    const { dispatch } = this.props;

    // get params
    let params = this._getParams(page);

    if (!isShort) {
      // delete sort_order from params obj
      delete params.sort_order
      delete params.sort_by
    }
    if (this.state.selectedSortBy) {
      params = { ...params, action_date_type: this.state.selectedSortBy?.value }
    }
    // dispatch action
    dispatch(getReportsByDateRange(params, key));
  };

  // Handle Search
  _handleSearch = (value) => {
    const { dispatch } = this.props;
    dispatch(getReportsByInput({ q: value }));

    // Set value to state
    this.setState({ inputValue: value });
    this.setState({ isSearch: true });
  };

  // Hanlde Pagination and filter change handler
  _handlePaginationAndFilter = (page, key, filters, sorter) => {
    const { isSearch } = this.state;
    const { dispatch } = this.props;
    const { inputValue, currentFilterData } = this.state;

    //  for all data
    let path = REPORTS.GET_REPORTS;

    //  for filter
    if (isSearch) {
      path = REPORTS.SEARCH;
    }

    this.setState({ page: page });
    // if (sorter.order === "ascend") {
    //   this.setState({ sorterOrder: "ASC" })
    // } else if (sorter.order === "descend") {

    //   this.setState({ sorterOrder: "DESC" })
    // }

    // Reset scroll position to the top when the component mounts
    window.scrollTo(0, 0);

    // in antd table, filter and pagination change event fires in one callback function. therefore to filter change and page change currentFilterData of state
    // is compared with filters. if gets matched, filter doesn't get changed meaning pagination has changed. if doesn't match refereing filter item has changed
    if (!_isEqual(currentFilterData, filters)) {
      this.setState({
        currentFilterData: filters,
      });
      return;
    }

    // Check if the path end with search
    if (path && path.includes("search")) {
      dispatch(getReportsByInput({ q: inputValue, page: page ?? 1 }));
    } else {
      this._getReports(page, key, true);
    }
  };

  // Download SLA Report
  _downloadSLAReport = () => {
    const { dispatch } = this.props;

    // get params
    const params = this._getParams();

    // dispatch action
    dispatch(downloadSLAReport(params));
  };

  // Download trip status Report
  _downloadTripStatusReport = () => {
    const { dispatch } = this.props;

    // get params
    let params = this._getParams();
    params = { ...params, action_date_type: this.state.selectedSortBy.value }  // action_date_type = "'created_at' /'end_date'"
    delete params.sort_order
    delete params.sort_by
    // dispatch action
    dispatch(downloadTripStatusReport(params));
  };

  // Download trip status Report
  _downloadExecutiveReport = () => {
    const { dispatch } = this.props;

    // get params
    const params = this._getExecutiveParams();

    // dispatch action
    dispatch(downloadExecutiveReport(params));
  };

  //opening view application page
  _openApplicationPage = (e, record) => {
    e.preventDefault();
    window.open(`/application/${record.id}`, "_blank");
  };

  // showing modal
  _showModal = (record) => {
    const { dispatch } = this.props;
    this.setState({
      visible: true,
      currentApplicationData: record,
    });

    dispatch(setSelectedApplication(record));
  };

  // Handle Ok
  _handleOk = () => {
    setTimeout(() => {
      this.setState({ visible: false });
    }, 3000);
  };

  // Close topsheet Modal
  _handleTopsheetCancel = () => {
    const { dispatch } = this.props;
    this.setState({
      visible: false,
      currentApplicationData: null,
    });
    dispatch(setSelectedApplication(null), setApplicationTopsheet(null));
  };

  // Handle Bank Report Download
  _handleBankReportDownload = (id) => {
    const { dispatch } = this.props;
    if (!id) {
      // Show error message
      showAlert("error", "Something went wrong!");
      return;
    }
    dispatch(downloadBankReport(id));
  };

  // Handle Modal
  _handleModal = (application) => {
    const { dispatch, isDeleteButtonLoading } = this.props;
    const params = this._getParams();

    // Check if Application id is not null
    if (!application?.id) {
      // Show Error Alert
      showAlert("error", "Something went wrong!");
      return;
    }

    confirm({
      title: "Are you sure delete this file?",
      content: `"${application?.file_no ?? "N/A"}" from "${application?.org_name ?? "N/A"
        }" will be deleted permanently.`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        dispatch(deleteApplication(application.id, params));
      },
      okButtonProps: {
        loading: isDeleteButtonLoading,
      },
    });
  };

  // Handle Status Update
  _handleStatusUpdate = (e, application) => {
    e.preventDefault();

    const { dispatch } = this.props;
    dispatch(openStatusUpdateModal(application));
  };

  render() {
    const {
      reloadModal,
      reports,
      isLoading,
      pagination,
      processes,
      isSubmitButtonLoading,
      isSearchButtonLoading,
      showDownloadButton,
      isDownloadButtonLoading,
      selectedApplication,
      isStatusUpdateModalOpen,
      allowedPermission,
    } = this.props;

    const { columns, visible, currentApplicationData, selectedGroup } =
      this.state;
    return (
      <div style={{ width: "100%" }}>
        <Card title={"Reports"} style={{ width: "100%" }} hoverable>
          <Space
            direction={"vertical"}
            size={"middle"}
            style={{ width: "100%" }}
          >
            <FilterWithDateOrg
              onSubmit={this._handleSubmit}
              isSubmitButtonLoading={isSubmitButtonLoading}
              isSearchButtonLoading={isSearchButtonLoading}
              showDate={isAllowedToAccess(allowedPermission, {
                menuName: "reports",
                subMenuName: "reports",
                permissionID: "7",
              })}
              // PERMISSION ID OF "reports.reports.filter.organization" is 8
              showOrg={isAllowedToAccess(allowedPermission, {
                menuName: "reports",
                subMenuName: "reports",
                permissionID: "8",
              })}
              showCity={isAllowedToAccess(allowedPermission, {
                menuName: "reports",
                subMenuName: "reports",
                permissionID: "9",
              })}
              showStatus={true}
              showVerifier={true}
              // PERMISSION ID OF "reports.reports.search" is 10
              showSearch={isAllowedToAccess(allowedPermission, {
                menuName: "reports",
                subMenuName: "reports",
                permissionID: "10",
              })}
              handleSearch={this._handleSearch}
              showSortBy={true}
              multipleSelect={true}
            />
            {processes?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  color: "red",
                  fontWeight: "bold",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <p
                  style={{
                    color: "red",
                    marginTop: "2px",
                    margin: "0px",
                    padding: "0px",
                    paddingRight: "4px",
                    fontSize: "11px",
                  }}
                >
                  Bulk Upload Process Running:
                </p>
                {processes?.map((process, index) => (
                  <div key={index} style={STYLES.processTag}>
                    <span>{process?.organization_name} : </span>
                    <span>{process?.process_count}</span>
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}

            <Table
              style={STYLES.tableStyle}
              size={"small"}
              columns={columns}
              dataSource={reports}
              bordered={true}
              // onChange={(pagination, filters, sorters) =>
              //   this._handlePaginationAndFilter(
              //     pagination.current,
              //     true,
              //     filters,
              //     sorters
              //   )
              // }
              loading={{
                indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
                spinning: isLoading,
              }}
              pagination={{
                pageSize: pagination?.per_page,
                total: pagination?.total,
                pageSizeOptions: [10],
                showSizeChanger: false,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} files`,
                onChange: (page, filters, sorters) => {
                  this._handlePaginationAndFilter(
                    page,
                    true,
                    pagination?.path,
                    filters,
                    sorters
                  );
                },
                current: pagination?.current_page,
                size: "small",
              }}
              footer={() => (
                <div style={{ textAlign: "right" }}>
                  {
                    // PERMISSION ID OF "reports.reports.file.sla_report_download" is 18
                    isAllowedToAccess(allowedPermission, {
                      menuName: "reports",
                      subMenuName: "reports",
                      permissionID: "18",
                    }) && (
                      <Button
                        style={{ marginRight: "5px" }}
                        type={"primary"}
                        icon={<DownloadOutlined />}
                        loading={isDownloadButtonLoading}
                        onClick={() => {
                          this._downloadSLAReport();
                        }}
                        disabled={
                          !showDownloadButton || reports?.length === 0
                        }
                      >
                        {"Download SLA Report"}
                      </Button>
                    )
                  }
                  {
                    // PERMISSION ID OF "reports.reports.file.trip_state_report_download" is 74
                    isAllowedToAccess(allowedPermission, {
                      menuName: "reports",
                      subMenuName: "reports",
                      permissionID: "74",
                    }) && (
                      <Button
                        style={{ marginRight: "5px" }}
                        type={"primary"}
                        icon={<DownloadOutlined />}
                        loading={isDownloadButtonLoading}
                        onClick={() => {
                          this._downloadTripStatusReport();
                        }}
                        disabled={reports?.length === 0}
                      >
                        {"Download Trip Status Report"}
                      </Button>
                    )
                  }
                  <Tooltip title={ selectedGroup && selectedGroup?.length > 0 ? "" : "Downloadable after filtering with an Organization"}>
                    <Button
                      type={"primary"}
                      icon={<DownloadOutlined />}
                      loading={isDownloadButtonLoading}
                      onClick={() => {
                        this._downloadExecutiveReport();
                      }}
                      disabled={reports?.length === 0 || !selectedGroup || (selectedGroup && selectedGroup?.length === 0)}
                    >
                      {"Download Executive Report"}
                    </Button>
                  </Tooltip>
                </div>
              )}
            />
          </Space>
        </Card>
        {isStatusUpdateModalOpen && selectedApplication?.id && (
          <StatusUpdateModal getParams={this._getParams} />
        )}
        {currentApplicationData?.id && reloadModal && (
          <Modal
            visible={visible}
            title="Update Topsheet for Trips"
            width={"80vw"}
            onCancel={this._handleTopsheetCancel}
            footer={null}
          >
            <UpdateTopsheet />
          </Modal>
        )}
      </div>
    );
  }
}

// styles
const STYLES = {
  tableStyle: {
    width: "100%",
    overflow: "auto",
  },
  processTag: {
    background: "#D61A3C",
    color: "white",
    margin: "0px !important",
    marginRight: "0px",
    paddingLeft: "6px",
    paddingTop: "1px",
    padding: "0px !important",
    paddingRight: "6px",
    borderRadius: "8px",
    fontSize: "10px",
  },
};

// PropTypes
Reports.propTypes = {
  reports: PropTypes.array,
  isLoading: PropTypes.bool,
  pagination: PropTypes.object,
  dispatch: PropTypes.func,
  isSubmitButtonLoading: PropTypes.bool,
  isSearchButtonLoading: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
  isDownloadButtonLoading: PropTypes.bool,
  isBankReportLoading: PropTypes.bool,
  isDeleteButtonLoading: PropTypes.bool,
  selectedApplication: PropTypes.object,
  isStatusUpdateModalOpen: PropTypes.bool,
  orgList: PropTypes.array,
};

Reports.defaultProps = {
  reports: [],
  isLoading: false,
  pagination: {},
  dispatch: () => null,
  isSubmitButtonLoading: false,
  isSearchButtonLoading: false,
  showDownloadButton: false,
  isDownloadButtonLoading: false,
  isBankReportLoading: false,
  isDeleteButtonLoading: false,
  selectedApplication: null,
  isStatusUpdateModalOpen: false,
  orgList: [],
  allowedPermission: null,
};

const mapStateToProps = (state) => ({
  isLoading: state?.reports?.isLoading ?? false,
  reports: state?.reports?.reports ?? null,
  socketStatus: state?.reports?.socketStatus ?? null,
  pagination: state?.reports?.pagination ?? null,
  processes: state?.reports?.processes ?? null,
  isSubmitButtonLoading: state?.reports?.isSubmitButtonLoading ?? false,
  isTopsheetUpdateLoading: state?.reports?.isTopsheetUpdateLoading ?? false,
  isSearchButtonLoading: state?.reports?.isSearchButtonLoading ?? false,
  showDownloadButton: state?.reports?.showDownloadButton ?? false,
  isDownloadButtonLoading: state?.reports?.isDownloadButtonLoading ?? false,
  isBankReportLoading: state?.reports?.isBankReportLoading ?? false,
  isDeleteButtonLoading: state?.reports?.isDeleteButtonLoading ?? false,
  selectedApplication: state?.reports?.selectedApplication ?? null,
  isStatusUpdateModalOpen: state?.reports?.isStatusUpdateModalOpen ?? false,
  reloadModal: state?.reports?.reloadModal ?? false,
  orgList: state?.common?.orgList ?? [],
  allowedPermission: state?.permission?.allowedPermission ?? null,
  user: state?.auth?.user ?? null,
  verifiers: state?.common?.verifiers ?? []
});

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
